/* ++++++++++++++++++++
+++ LAYOUT: SECTION +++
++++++++++++++++++++ */

// A section is a grouping of content, typically with a header, possibly with a footer. A '.section' with a '.container' results in a consistent paddings, margins and max-width for content while allowing for full width background colors on the section and centred background colors on the container.

.section {
    @extend %section;

    &.dark,
    &.primary,
    &.secondary {
        color: $white;

        input,
        textarea,
        select {
            border-color: transparent;
        }
    }

    &.white {
        background: $white;
    }

    &.grey {
        background: $grey90;
    }

    &.dark {
        background: $grey15;
    }

    &.primary {
        background: $primary;
        color: white;
    }

    &.secondary {
        background: $secondary;
    }

    &.primary.gradient {
        background: rgb(0, 95, 182);
        background: -moz-linear-gradient(313deg, rgba(0, 95, 182, 1) 0%, rgba(0, 72, 138, 1) 100%);
        background: -webkit-linear-gradient(313deg, rgba(0, 95, 182, 1) 0%, rgba(0, 72, 138, 1) 100%);
        background: linear-gradient(313deg, rgba(0, 95, 182, 1) 0%, rgba(0, 72, 138, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#005fb6", endColorstr="#00488a", GradientType=1);

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: white;
        }
    }


    &.subnav {
        background: $white;
    }

    &.sec-links {
        border-bottom: solid 2px rgba($primary, 0.05);
        padding-bottom: $gap * 1.5;
        padding-top: $gap * 1.5;

        .container {
            max-width: none;
            padding: 0;
        }

        &-outer {
            padding-bottom: 0;

            .container {
                padding: 0;
            }
        }
    }

    &-header {
        text-align: center;

        &-title {
            color: $primary;
            @include font-size(35);
            margin-bottom: 30px;

            @include break(medium) {
                @include font-size(42);
            }

            +.section-header-summary {
                padding-top: $gap;
                margin-top: -30px;
            }

            .resources & {
                width: 100%;
            }
        }

        &-summary {
            font-size: 1.125em;
            max-width: 500px;
            padding-bottom: $gap;

            .resources & {
                margin-left: 0;
            }
        }

        .button {
            margin-left: 0;
            align-self: flex-end;

            @include break(tablet) {
                margin-left: auto;
                margin-right: 0;
            }
        }

        .resources & {
            text-align: left;
            @extend %flex;
        }
    }

    &-footer {
        @extend %container;
        margin-top: $gap;
        text-align: center;

        @include break(small) {
            margin-top: $gap * 2;
        }

    }

    &.inline-section {

        .section-header {
            @include break(medium) {
                text-align: left;
            }
        }

        .accordion-outer,
        .pullquote {
            @include break(medium) {
                margin-left: 0;
                width: 70%;
            }
        }

        .pullquote {
            &-image {
                +.pullquote-info {
                    blockquote {
                        font-size: 1.3em;
                    }
                }
            }

            cite {
                font-size: 1em;
            }
        }

    }
}

.container {
    @extend %container;

    &.centered {
        text-align: center;
    }

    &.small {
        max-width: $two-thirds-column;
    }

    &.white,
    &.grey,
    &.dark,
    &.primary,
    &.secondary {
        padding: $gap;

        @include break(small) {
            padding: $gap * 2;
        }

        @include break(large) {
            border-radius: $corner;
            max-width: $page-width - $gap * 4;
        }
    }

    &.dark,
    &.primary,
    &.secondary {
        color: $white;
    }

    &.white {
        background: $white;
    }

    &.grey {
        background: $grey90;
    }

    &.dark {
        background: $grey15;
    }

    &.primary {
        background: $primary;
    }

    &.secondary {
        background: $secondary;
    }
}
/* +++++++++++++++++
+++ LAYOUT: MAIN +++
+++++++++++++++++ */

// Main is the high level container of content, in between the site header and footer

.main {
    background: $body-color;
    display: block;
    margin: 0;

    &-section {
        @extend %flex;
        @extend %section;
        align-items: flex-start;
        margin: auto;
        max-width: $page-width;
        overflow: visible;

        .news-single &{
            .container{
                max-width:#{$page-width - 10em};
            }
        }

        .resource-single &{
            .container{
                display:flex;
                flex-direction:column;

                @include break(medium){
                    display:block;
                }
            }
        }
    }

    &-header,
    &-footer {
        text-align: center;
    }

    &-header {
        
        &-title{
            @extend %h1;
            @include font-size(48);
            text-transform:uppercase;
        }

        &-summary {
            p {
                font-size: 1.2em;
            }
        }
    }

    &-footer{
        padding:$gap 0 $gap * 2 0;

        .news-listing &{
            padding-top:0;
        }
    }

    @include break(medium) {
        &-section {
            flex-flow: row wrap;
            > * {
                flex: 1 66.66%;
            }
        }
    }
}

/* ++++++++++++++++++++
+++ LAYOUT: GENERAL +++
++++++++++++++++++++ */

// Universal margins
body,
br,
dd,
option,
td,
th,
svg {
    margin-top: 0;
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-feature-settings:"kern" 1;
    font-kerning: normal;
    background: $grey15;
    color: $grey20;
    font: $normal 1em/1.5 $font-body;
    position: relative;
    width: 100%;
}

//=======================================
// Typography
//=======================================

h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

h1 {
    @extend %h1;
}

h2 {
    @extend %h2;
}

h3 {
    @extend %h3;
}

h4 {
    @extend %h4;
}

h5 {
    @extend %h5;
}

h6 {
    @extend %h6;
}

figcaption {
    @extend %font-content;
    background-color: $grey95;
    display: inline-block;
    font-size: 0.9em;
    overflow-wrap: break-word;
    padding: $gap / 2 $gap;
    word-wrap: break-word;

    a {
        @extend %text-hover;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

strong {
    font-weight: $bold;
}

em {
    font-style: italic;
}

hr {
    border: none;
    border-top: $line solid $grey80;
}

img {
    display: block;
    height: auto;
    max-width: 100%;
    text-align: center;
}

sup {
  vertical-align: super;
  font-size: small;
}

sub {
  vertical-align: sub;
  font-size: small;
}

li {
    list-style: none;
}

//=======================================
// Global placeholders and classes
//=======================================

// Full width section
%section {
    margin: 0;
    overflow: hidden;
    padding: $gap * 2 0;
    width: 100%;

    @include break(medium) {
        padding: $gap * 3 0;
    }
}

// Basic layout for a container (usually within a %section)

.components {   
    .container {
        padding: 0 $gap * 1;
        
        @include break(small){
            padding: 0 $gap * 2;
        }
    }
}

%container {
    max-width: $page-width;
    padding: 0 $gap;
    position: relative;
    width: 100%;

    @include break(small) {
        padding: 0 $gap * 2;
    }
}

// Card with a white background and drop shadow
%card {
    background: $white;
    box-shadow: $shadow;
    overflow: hidden;
}

// Hover styles for links and images
%text-hover {
    @extend %transition;

    &:hover {
        color: $secondary;
    }
}

%image-hover {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
    display: block;
    overflow: hidden;

	img {
	    @extend %transition;
	    transform: scale(1);

	    &:hover,
	    &:focus {
			transform: scale(1.05);
	    }
	}
}
%object-fit {
    height: 100%;
    object-fit: cover;
    width: 100%;
    
    .no-object-fit {
		font-family: 'object-fit: cover;';
    }
}

// Gives any first paragraphs a drop cap
%drop-cap {
    & > p:first-child::first-letter {
        float: left;
        font-size: 340%;
        line-height: 0.6;
        margin: 10px 10px 0 0;
    }
}

// Add a dark filter
%overlay {
    position: relative;

    &:after {
        background: rgba($black, 0.3);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

%shaded-top,
%shaded-bottom {
    position: relative;

    &:before {
        content: "";
        height: 100%;
        left: 0;
        opacity: 0.5;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

%shaded-top:before {
    @include linear-gradient(
        top, to bottom,
        rgba($black, 1) 0%,
        rgba($black, 0.738) 19%,
        rgba($black, 0.541) 34%,
        rgba($black, 0.382) 47%,
        rgba($black, 0.278) 56.5%,
        rgba($black, 0.194) 65%,
        rgba($black, 0.126) 73%,
        rgba($black, 0.075) 80.2%,
        rgba($black, 0.042) 86.1%,
        rgba($black, 0.021) 91%,
        rgba($black, 0.008) 95.2%,
        rgba($black, 0.002) 98.2%,
        rgba($black, 0) 100%
    );
}

%shaded-bottom:before {
    @include linear-gradient(
        bottom, to top,
        rgba($black, 1) 0%,
        rgba($black, 0.738) 19%,
        rgba($black, 0.541) 34%,
        rgba($black, 0.382) 47%,
        rgba($black, 0.278) 56.5%,
        rgba($black, 0.194) 65%,
        rgba($black, 0.126) 73%,
        rgba($black, 0.075) 80.2%,
        rgba($black, 0.042) 86.1%,
        rgba($black, 0.021) 91%,
        rgba($black, 0.008) 95.2%,
        rgba($black, 0.002) 98.2%,
        rgba($black, 0) 100%
    );
}

// Sideways text
%sideways {
    @include transform(rotate(180deg));
    -moz-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    -ms-writing-mode: vertical-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    white-space: nowrap;
}

// Vertical centered container
%vertical {
    @include transform(translateY(-50%));
    margin-top: 0;
    position: absolute;
    top: 50%;
    width: 100%;
}

.text-hover {
    @extend %text-hover;
}

// Makes any children float left and have consistent margins
.inline-group {
    @extend .clearfix;
    margin-top: 0;

    & > * {
        float: left;
        margin: $gap $gap 0 0;
    }
}

// Hide things
.visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	padding: 0;
	position: absolute !important;
	overflow: hidden;
	width: 1px;
}

.clearfix:after {
    clear: both;
    content: "";
    display: table;
}

.cookie-notice {
    background: $grey20;
    bottom: 0;
    color: $white;
    font-size: 0.9rem;
    line-height: 1.5;
    padding: $gap / 2;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: map-get($zindex, popup);
}

.skip-link {
  margin: 0;
  position: fixed;
  top: -$gap * 10;
  z-index: map-get($zindex, header);
  
  // Visually hidden
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute !important;
  overflow: hidden;
  width: 1px;

  &:focus {
    margin: $gap / 2 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}

// Cookie control styling

#ccc {
	.checkbox-toggle {
	    border-color: $primary !important;
	    background: $primary !important;
	}
	h2, h3 {
		color: $primary !important;
	}
}
.ccc-panel {
	.ccc-notify-button {
	    background: $primary !important;
	    border: none !important;
	    border-radius: 25px !important;
	    box-shadow: 4px 6px 20px 0 rgba(117,117,117,0.5);
	    
		&:hover {
			background: $secondary !important;
			box-shadow: none;
			color: $white;
		}
	}
}
.search{
    &-cover{
        position:fixed;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background:$white;
        z-index: 1002;
        @extend %flex;
        align-items:center;
        justify-content: center;
        padding:50px;
        opacity:0;
        pointer-events:none;
        transition:ease 0.3s;

        &.active{
            opacity:1;
            pointer-events:all;
        }

        &-inner{
            width:100%;

            @include break(large){
                width:60%;
            }
    
            form{
                @extend %flex;
                
            }
    
            input{
                width:100%;
                color:$blue;
                border:solid 5px $blue;
                border-radius:0px;
                padding:0 20px;
                box-shadow:$shadow;
                background:white;                
                font-weight:bold;
                height:auto;
                text-transform:uppercase;
                margin:0;
                @include font-size(25);

                @include break(medium){
                    width:calc(100% - 170px);
                    @include font-size(30);
                    padding:0 30px;
                }

                @include break(large){
                    @include font-size(40);
                }

                @include placeholder(){
                    @include font-size(25);
                    font-weight:bold;
                    color:$blue;
                    text-transform:uppercase;

                    @include break(medium){
                        @include font-size(30);
                    }

                    @include break(large){
                        @include font-size(40);
                    }
                }
            }
    
            button{
                width:100%;
                height:50px;
                margin:10px auto 0 auto;

                @include break(medium){
                    width:160px;
                    height:auto;
                    margin:0 0 0 10px;
                }
            }
        }
    }

    &-close{
        position:absolute;
        height:60px;
        width:60px;
        cursor:pointer;
        top: 10px;
        right: 10px;
        cursor: pointer;
        transform: scale(0.8);

        @include break(medium){
            top:40px;
            right:40px;
            transform:scale(1);
        }

        &:before{
            content:'';
            height:6px;
            width:100%;
            background:$blue;
            position:absolute;
            left:0;
            top:27px;
            transform:rotate(45deg);
        }

        &:after{
            content:'';
            height:6px;
            width:100%;
            background:$blue;
            position:absolute;
            left:0;
            top:27px;
            transform:rotate(-45deg);
        }
    }
}
/* ++++++++++++++++++++++
+++ LAYOUT: ACCORDION +++
++++++++++++++++++++++ */

.accordion {
	&-outer {
		background-color: $white;
		border-radius: $corner;
		box-shadow: $shadow;
		padding: 0 $gap * 1.5;
		position: relative;	
	}
	
	&-item {
		padding: $gap 0;

	    & + & {
	        border-top: $line solid $grey80;
	    }
	}

	&-button {
		background: none;
		border-radius: 0;
		color: $black;
		display: block;
		font-size: 1.25rem;
		padding: 0 $gap * 1.5 0 0;
		position: relative;
		text-align: left;
		width: 100%;

		.icon {
			display: inline-block;
			height: 1.05em;
			position: absolute;
			right: 0;
			top: 0.3em;
			transition: 0.235s transform ease-in-out;
			width: 1.05em;			
		}

		&:hover,
		&:focus {
			background: none;
			outline: 0;
		}

		&:active,
		&.active {
			.icon {
				transform: rotate(90deg);
				transition: 0.235s transform ease-in-out;
			}
		}
	}

	&-panel {
		display: none;
		padding-top: $gap;
		
		p {
			color: $black;	
		}
	}
}

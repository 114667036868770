/* +++++++++++++++++++++++++++
+++ LAYOUT: RESPONSIVE NAV +++
+++++++++++++++++++++++++++ */

// Layout and styling of the navigation at mobile and tablet sizes

.rnav {
    background: $blue;
    color: $white;
    display: none;
    margin-top: 0;
    padding-top: 0;

    #rnav-open:checked + & {
        display: block;
    }

    * {
        margin-top: 0;
    }

    .search {
        margin: 0 $gap $gap;
        padding-top: $gap;

        &-input {
            background: rgba($white, 0.1);
            border-color: transparent;
            color: $white;
        }
    }

    &-item {
        border-top: $line solid rgba($white, 0.1);
        position: relative;
        font-family:$font-header;
        text-transform:uppercase;

        &-link {
            @extend %transition;
            display: block;
            padding: $gap $gap * 3 $gap $gap;
            text-align: left;

            &:hover {
                background: rgba($white, 0.1);
            }
        }
    }

    &-dropdown {
        background: rgba($black, 0.2);
        display: none;

        .rnav-item-link{
            @include font-size(14);
        }

        .rnav-checkbox:checked ~ & {
            display: block;
        }
    }
    
    &-secnav {
	    background: lighten($grey20, 2);
	    
	    &-item {
		    border-bottom: $line solid rgba($white, 0.1);
		    display: block;
			padding: 0 $gap / 2;
			
			&:last-child {
				border-bottom: 0;
			}
		    
		    .rnav-item-link {
			    font-size: 0.9em;
			    font-weight: $normal;
			    
			    &:hover {
				    background-color: transparent;
				    border-color: $primary;
				    color: $primary;
			    }
		    }
	    }
    }

    &-arrow {
        cursor: pointer;
        padding: $gap;
        position: absolute;
        right: 0;
        top: 0;

        .icon {
            @include transition(transform 0.4s ease);
            display: block;

            .rnav-checkbox:checked + .rnav-arrow & {
                @include transform(rotate(-180deg));
            }
        }
    }

    &-button {
        border-top: $line solid rgba($white, 0.1);
        padding: $gap;

        .button{
            padding:$gap / 2;
            width:100%;

            &:hover{
                background:$orange;
            }
        }
    }

    @include break(medium) {
        #rnav-open:checked + & {
            display: none;
        }
    }
}

/* +++++++++++++++++++
+++ LAYOUT: HEADER +++
+++++++++++++++++++ */

// Site header bar
.header {
    @extend %flex;
    background: $header-color;
    position: relative;
    width: 100%;
    z-index: 1001;
    transition: ease 0.2s;

    &-inner {
        @extend %flex;
        @extend .container;
        justify-content: space-between;
        padding: $gap;
    }

    &-logo {
        margin-left: 0;
        transition: ease 0.2s;

        @media screen and (max-width:map-get($breakpoints, medium)) {
            height: 50px !important;
            width: 85px !important;
        }

        @include break(medium) {
            width: 185px;
            height: 110px;
        }

        img {
            width: 100%;
        }
    }

    &-links {
        display: none;
        margin: 0;
        align-self: center;

        @include break(medium) {
            display: block;
        }
    }

    &-nav {
        margin-right: 0;
        align-self: center;

        &-list {
            display: none;

            @include break(medium) {
                display: block;
                margin: 0;
            }
        }

        label {
            margin-top: 0;
        }
    }

    &-subnav {
        display: none;

        @include break(medium) {
            @include flexbox(100%);
            background-color: $grey90;
            display: block;
            padding: $gap / 4 $gap;

            &-list {
                text-align: right;

                .subnav-item {
                    display: inline-block;

                    .nav-item-link {
                        font-size: 0.9em;
                        margin: 0 $gap / 2;
                    }
                }
            }
        }
    }

    .pr-single &, .news-single & {
        box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
    }

}

// Navigation items in the header and footer
.nav-item {
    display: inline-block;
    vertical-align: middle;
    position: relative;

    &:hover .dropdown {
        @include transition(opacity 0.2s 0.3s ease);
        opacity: 1;
        visibility: visible;
        pointer-events: all;

        .nav-item {
            float: none;
        }
    }

    .button {
        margin-left: $gap * 1.5;
        background: $orange;

        &:hover {
            background: $primary;
        }
    }

    &-search {
        color: $primary;
        margin-left: $gap * 0.5;
        cursor: pointer;

        &.mobile {
            display: block;
            position: absolute;
            right: 82px;
            @include font-size(25);
            color: white;
            height: 82px;
            width: 82px;
            background: darken($primary, 10%);
            top: 0;

            .icon {
                position: absolute;
                @include centerer(true, true);
            }

            @include break(medium) {
                display: none;
            }
        }

        &.desktop {
            display: none;

            @include break(medium) {
                display: inline-block;
            }
        }
    }

    &-link {
        @extend %h5;
        @include font-size(20);
        text-transform: uppercase;
        color: $primary;
        display: block;
        margin: 0 $gap;
        padding: $gap / 2 0;

        .social & {
            margin: 0;
            padding: $gap / 2 $gap;
        }

        &.current {
            box-shadow: inset 0px -8px 0px 0px $orange;
        }

        &:hover {
            box-shadow: inset 0px -6px 0px 0px $orange;
        }
    }

    &-arrow {
        width: $gap;
    }
}

// Responsive nav hamburger button
.menu-button {
    display: block;
    position: absolute;
    height: 82px;
    width: 82px;
    background: $primary;
    right: 0;
    top: 0;

    &-toggle {
        position: absolute;
        height: 40px;
        width: 47px;
        top: 21px;
        left: 19px;
        border-top: 5px solid white;
        border-bottom: 5px solid white;

        &:after {
            content: '';
            height: 5px;
            width: 100%;
            position: absolute;
            top: 12px;
            left: 0;
            background: White;

            .nav-open & {
                transform: rotate(-45deg);
                top: 17px;
            }
        }

        &:before {
            content: '';
            height: 5px;
            width: 100%;
            position: absolute;
            top: 12px;
            left: 0;
            background: White;

            .nav-open & {
                transform: rotate(45deg);
                top: 17px;
            }
        }

        .nav-open & {
            border: 0px;
        }
    }

    @include break(medium) {
        display: none;
    }
}

// Search trigger
.search-open {
    background: none;
    color: $grey20;
    cursor: pointer;
    margin: 0 !important;
    padding: 0;

    &:hover {
        background-color: transparent;
    }
}

// Dropdown menu styling
.dropdown {
    @include transition(visibility 0s 0.4s ease, opacity 0.2s 0.2s ease);
    background: $secondary;
    opacity: 0;
    margin-top: $gap * 1.1;
    position: absolute;
    visibility: hidden;
    top: 100%;
    width: 250px;
    left: calc(50% - 125px);
    pointer-events: none;
    filter: drop-shadow(0px 10px 7px rgba(0, 0, 0, 0.2));

    &-item {

        text-align: center;
        line-height: 1.2;
        z-index: 1;
        position: relative;
        background: $blue;

        .nav-item-link {
            padding: 15px 10px;
            display: block;
            color: white;
            @include font-size(16);

            &:after {
                display: none;
            }

            &:hover {
                color: $black;
                box-shadow: none;
            }
        }

        // &:nth-of-type(even){
        //     background:darken($blue, 5%);
        // }

        &:first-of-type {
            position: relative;
            z-index: 1;

            &:after {
                content: '';
                width: 0;
                height: 0;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid $blue;
                position: absolute;
                top: -15px;
                left: calc(50% - 15px);
            }
        }

        &:hover {
            background: $orange;


            &:after {
                border-bottom-color: $orange;
            }

            .nav-item-link {
                color: $white;
            }
        }
    }

    &:before {
        content: '';
        height: $gap * 1.1;
        width: 100%;
        top: -$gap * 1.1;
        position: absolute;
        left: 0;
    }

    &:hover {
        opacity: 1;
        visibility: visible;
    }
}

// CMS tweaks
@if $wordpress {
    #wpadminbar {
        box-sizing: content-box;
        margin-top: 0;

        img {
            display: inline;
        }

        @media screen and (max-width: 600px) {
            position: fixed;
        }
    }
}

@if $drupal {
    .header-nav-outer {
        .nav-item-link {
            &.is-active {
                color: $grey15;
                font-weight: $bold;
            }
        }
    }
}
/* ++++++++++++++++++++++++
+++ LAYOUT: MEDIA BLOCK +++
++++++++++++++++++++++++ */

/* ++++++++++++++++++++++++
+++ LAYOUT: MEDIA BLOCK +++
++++++++++++++++++++++++ */

.media-block {
	@extend %flex;
	min-height: 420px;
	
	&-outer {

		&.right {
			.media-block {
				@include break(medium) {
					flex-flow: row-reverse nowrap;
				}
			}
		}

	    &.secondary {
		    .media-block {
				background: transparent;
				
				&-info {
					color: $black;
				}
		    }
		}
		
	    &.video {
		    .media-block {
			    min-height: auto;   
			    
			    &-element {
				    @include flexbox(100%);
					overflow: hidden;
					
					@include break(medium) {
					    @include flexbox(50%);
					}
					
				    .video-outer {
						display: block;
						padding-bottom: 56.25%; /* 16:9 */
						height: 0;
						position: relative;
			
						iframe {
							height: 100%;
							left: 0;
							position: absolute;
							top: 0;
							width: 100%;
						}   
				    }
			    }
			    &-info {
					@include break(medium) {
					    @include flexbox(50%);
					}
			    }
		    }
		}
		
		& + .media-block-outer:not(.primary):not(.secondary){
			padding-top:0;
		}
	}
	
    &-element, &-info {
	    margin: 0;
	}
	
    &-info {
	    @extend %flex;
		align-items: center;
	    padding: $gap * 1.5 $gap * 1.5 0 0;
	    
	    @include break(medium) {
		    @include flexbox(50%);
		    padding: $gap * 3;
	    }
	    @include break(large) {
		    padding: $gap * 4 $gap * 3;
	    }
	    
	    .button {
			font-size: 1.2em;
			margin-top:20px;

			.primary &{
				background:white;
				color:$primary;

				&:hover{
					background:$secondary;
					color:white;
				}
			}
	    }
	}
	
    &-content {
	    p {

			margin-top:20px;
		    
		    a {
			    @extend %text-hover;
			    text-decoration: underline;
		    }
	    }
	}
	
    &-element {
	    overflow: hidden;
	    position: relative;
	    
	    @include break(medium) {
		    @include flexbox(50%);
	    }
	    
	    &-play {
		    @extend .button;
		    @include centerer(true, true);
		    background-color: $orange;
		    display: inline-block;
		    width: auto;
			z-index: 2;
			background:$secondary;
	    }
	    
	    img {
            height: 100%;
            object-fit: cover;
            width: 100%;
            
            @include break(medium) {
	            border-radius: 0;
	            left: 0;
	            position: absolute;
	            top: 0;
            }

            .no-objectfit & {
                width: auto;
            }
	    }
	}
	
    &-title {
	    font-size: 1.4em;
	    
	    @include break(tablet) {
		    font-size: 2em;	    
	    }
	    
		a {
			@extend %transition;
			
			&:hover {
				color: $primary;
			}
		}

		.primary &{
			color:white;
		}
    }
}

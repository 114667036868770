/* +++++++++++++++++++++++
+++ LAYOUT: PAGINATION +++
+++++++++++++++++++++++ */

// Pagination links for the listing and features pages

%pagination {
    @extend %flex;
    @extend %font-meta;
    text-align: center;
    justify-content: center;

    a {
        @extend %transition;
    }

    &-block {
        margin: 0 5px;
    }

    &-number {
        display: block;
    }

    &-element {
        display: block;
        padding: $gap / 4 $gap / 2;
        overflow: hidden;
    }

    &-arrow {
        @extend .button;
        color: transparent;
        display: block;
        height: 50px;
        width: 50px;
        padding: 0;
        position: relative;
    }

    @include break(small) {

        &-block {
            margin: 0 $gap / 2;
        }

        &-element {
            display: block;
        }
    }
}

@if $wordpress {
    .pagination {
        @extend %pagination;

        &_block {

            @extend %pagination-block;

            &.next {
                float: right;
            }

            &.numeral {
                height: 50px;
                width: 50px;
            }

            &.view_all {
                display: none;
            }

        }

        &_element {

            @extend %pagination-element;

            &.prev,
            &.next {
                @extend %pagination-arrow;
                background: $secondary;

                &:after {
                    content: '';
                    position: absolute;
                    top: 10%;
                    left: 10%;
                    height: 80%;
                    width: 80%;
                    background: url('../svg/icons/arrow-right.svg');
                    background-position: center center;
                    background-size: cover;
                }

                &:hover {
                    background: $primary;
                }
            }

            &.prev {
                transform: scaleX(-1);
            }

            &.numeral {
                height: 50px;
                width: 50px;
                line-height: 33px;
                border: solid 3px $primary;
                color: $primary;
                font-family: $font-header;
                @include font-size(22);
                transition: ease 0.3s;

                &.current {
                    background: $primary;
                    color: white;

                    &:hover {
                        background: $primary;
                        color: white;
                    }
                }

                &:hover {
                    background: rgba($primary, 0.1);
                }
            }
        }
    }
}
/* ++++++++++++++++++++
+++ LAYOUT: FILTERS +++
++++++++++++++++++++ */

.filter {
	@extend %flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: $gap * 1.5 $gap 0;
    
    @include break(small) {
	    padding: $gap * 1.5 0 0 0;
    }

    @include break(tablet){
        max-width: 70%;
    }

    @include break(medium){
        max-width: $two-thirds-column;
        
        .postcode-listing & {
            max-width: 350px;
        }
    }

    &-block {
        flex: 0 100%;
        padding: 0 0 $gap * 1.5;
        text-align: left;

        @include break(small){
            padding: 0 $gap * 2.5 $gap * 1.5;
        }

        &:last-of-type{
            padding-bottom:0;

            @include break(tablet){
                margin-top:$gap;
            }

            @include break(medium){
                margin-top:0;
            }
        }
        
        @include break(tablet){
	        flex: 1 12em;
            padding: 0 $gap / 2;
            
            &.slim{
                flex:0;
            }
        }
        
        label {
	        color: $white;
            font-weight: $bold;
            margin-top:0px;
        }

    }

    &-submit {
        @extend .button;
        border-radius:0;
        width:100%;
    }

    &-resources{
        padding:$gap * 1 0 $gap * 2 0;
    }
}
/* ++++++++++++++++++++
+++ LAYOUT: WYSIWYG +++
++++++++++++++++++++ */    
    
.wysiwyg {
	margin: 0;
	
	&.center {
		.content {
			margin: 0 auto;		
		}
	}
	
	.content {
		margin: 0;
		
		@include break(medium) {
			width: 70%;	
		}		
	}

	.news-single &{
		padding-top:0;

		.container{
			max-width:#{$page-width - 10em};
		}

		& + .wysiwyg{
			padding:0;
		}
	}
}
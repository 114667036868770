/* ++++++++++++++++++++
+++ LAYOUT: ARTICLE +++
++++++++++++++++++++ */

// Article represents the part of a page that includes posted content including title and summary. Displaying as an inline-block allows the article to center nicely with or without a sidebar present.

.article {
    margin-top: 0;
    padding: $gap * 1 0 0;
    position: relative;
    width: 100%;
    
    @include break(small) {
	    padding: $gap * 2 $gap;    
    }

    &-media {
	    overflow: hidden;
	    position: relative;
	    
	    @include break(medium) {
		    height: 60vh;
	    }
	    
	    img {
            width: 100%;
            
            @include break(medium) {
	            height: 100%;
	            left: 0;
	            object-fit: cover;
	            position: absolute;
	            top: 0;
            }
	    }
    }

    &-header {
        &-summary {
            p {
                font: $light 1.33em/1.5 $font-header;
            }
        }
    }

    &-footer {
        border-top: $line solid $grey80;
        margin-top: $gap * 2;
        padding-top: $gap;
    }

    @include break(small) {
        padding: 0;
    }

    @include break(medium) {
	    @include flexbox(70%);
        float: left;
    }
}

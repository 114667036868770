/* ++++++++++++++++++++++
+++ LAYOUT: PULLQUOTE +++
++++++++++++++++++++++ */

.pullquote {
	@extend %flex;
	font-family: $font-quote;
	justify-content: center;
	margin: 0 auto;
	text-align: center;

	flex-direction: row-reverse;
	padding: 0;

	// .impact &{
	// 	flex-direction:row-reverse;
	// 	padding:0;
	// }

	@include break(small) {
		align-items: center;
		text-align: left;

		text-align: center;

		// .impact &{
		// 	text-align:center;
		// }
	}

	@include break(medium) {
		padding: $gap * 2 $gap * 3;
	}

	@include break(xlarge) {
		margin: 0 auto;
		padding: $gap * 2 0;
		max-width: $page-width;
	}

	&-image {
		@include flexbox(250px);
		height: 250px;
		margin: 0;
		border-radius: 100%;
		overflow: hidden;

		margin-right: auto;
		margin-bottom: $gap;
		@include flexbox(200px);
		height: 200px;

		// .impact &{
		// 	margin-right:auto;
		// 	margin-bottom:$gap;
		// 	@include flexbox(200px);	
		// 	height:200px;
		// }

		@include break(small) {
			@include flexbox(400px);
			height: 400px;

			margin-left: auto;

			// .impact &{
			// 	margin-left:auto;
			// }
		}

		@include break(medium) {
			@include flexbox(250px);
			height: 250px;
			margin-left: 0;

			margin-bottom: 0;
			@include flexbox(300px);
			height: 300px;

			// .impact &{
			// 	margin-bottom:0;
			// 	@include flexbox(300px);	
			// 	height:300px;
			// }
		}

		@include break(large) {

			@include flexbox(400px);
			height: 400px;

			// .impact &{
			// 	@include flexbox(400px);	
			// 	height:400px;
			// }
		}

		img {
			@extend %object-fit;
		}

		+.pullquote-info {
			@include flexbox(100%);
			text-align: center;
			margin: 40px 0 0 0;


			border: none;
			padding: 0;
			margin: 0;
			text-align: left;

			// .impact &{
			// 	border:none;
			// 	padding:0;
			// 	margin:0;
			// 	text-align:left;
			// }

			@include break(small) {
				@include flexbox(80%);

				@include flexbox(100%);
				text-align: center;

				// .impact &{
				// 	@include flexbox(100%);
				// 	text-align:center;
				// }
			}

			@include break(medium) {
				@include flexbox(60%);
				margin: 0 0 0 60px;
				text-align: left;

				flex: 1;
				max-width: 100%;
				padding-right: $gap * 4;
				text-align: left;

				// .impact &{
				// 	flex:1;
				// 	max-width:100%;
				// 	padding-right:$gap * 4;
				// 	text-align:left;
				// }
			}

			blockquote {
				font-size: 1.8em;

				@include font-size(30);

				// .impact &{
				// 	@include font-size(30);
				// }

				@include break(medium) {
					font-size: 2em;

					@include font-size(35);

					// .impact &{
					// 	@include font-size(35);
					// }
				}

				@include break(large) {

					@include font-size(45);

					// .impact &{
					// 	@include font-size(45);
					// }
				}
			}
		}
	}

	&-info {
		@include flexbox(100%);
		position: relative;
		padding: 0;

		@include break(tablet) {
			@include flexbox(90%);
			margin-left: 0;
		}

		@include break(medium) {
			@include flexbox(80%);
			margin: auto;
		}

		@include break(large) {
			@include flexbox(70%);
		}

		* {
			position: relative;
			z-index: 3;
		}

		a {
			margin-top: $gap;
		}

		@extend %flex;
		flex-direction:column;

		// .impact &{
		// 	@extend %flex;
		// 	flex-direction:column;
		// }
	}

	blockquote {
		font-family: $font-header;
		font-weight: $bold;
		@include font-size(28);
		line-height: 1.3;
		padding: 0;
		position: relative;
		letter-spacing: -1.5px;
		color: $primary;

		@include break(medium) {
			@include font-size(35);
		}

	}

	cite {
		font-weight: $bold;
		font-family: $font-header;
		color: $grey30;
		@include font-size(18);
		padding: 0;
		background: transparent;
		display: block;
		width: 100%;
		font-style: normal;
		margin-top: $gap;

		order: -1;
		background: none;
		@extend %h5;
		color: $secondary;
		letter-spacing: 2px;
		padding: 0;
		padding-bottom: $gap / 1.5;
		margin-left: 0;
		text-transform: uppercase;

		@include break(small) {
			margin-left: auto;
		}

		@include break(medium) {
			margin-left: 0;
		}

		// .impact &{
		// 	order:-1;
		// 	background:none;
		// 	@extend %h5;
		// 	color:$secondary;
		// 	letter-spacing:2px;
		// 	padding:0;
		// 	padding-bottom: $gap / 1.5;
		// 	margin-left:0;
		// 	text-transform:uppercase;

		// 	@include break(small){
		// 		margin-left:auto;
		// 	}

		// 	@include break(medium){
		// 		margin-left:0;
		// 	}
		// }
	}

	&-button {
		display: none;

		display: inline-block;

		// .impact &{
		// 	display:inline-block;
		// }

		margin-left: 0;

		@include break(small) {
			margin-left: auto;
		}

		@include break(medium) {
			margin-left: 0;
		}
	}

	&-share {
		display: block;
		position: relative;

		@include break(medium) {
			display: inline-block;
			padding-left: $gap * 5;
			top: -4px;
		}

		&-title {
			@extend %font-content;
			color: $grey30;
			font-size: 0.7em;
			font-weight: $normal;
			padding: 0 0 0 $gap * 4;
			position: relative;
			text-transform: uppercase;

			&:before {
				background-color: $grey30;
				content: '';
				display: block;
				height: 1px;
				left: 0;
				position: absolute;
				top: 6px;
				width: 36px;
			}
		}

		&-element {
			@extend %transition;
			background-color: $twitter;
			border-radius: 100%;
			color: $white;
			display: inline-block;
			height: 30px;
			margin: 0 0 0 $gap / 2;
			text-align: center;
			width: 30px;

			.icon {
				margin-top: 3px;
			}
		}
	}

}
//+++++++++++++++++++++++
//+++ HELPERS: LAYOUT +++
//+++++++++++++++++++++++

// Make a flexbox container
%flex {
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

// responsive helper for checking sizes etc
@if $debug {

    .responsive-helper {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100000000000000000;
    }

    .responsive-helper p {
        color: white;
        background: black;
        padding: 8px;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 10px;
        text-align:center;
        width:100px;
        font-weight: bold;
        display:none;

        &.base{
            display:block;
        }

        @include break(small){
            &.small{
                display:block;
            }
        }

        @include break(medium){
            &.medium{
                display:block;
            }
        }

        @include break(tablet){
            &.tablet{
                display:block;
            }
        }

        @include break(large){
            &.large{
                display:block;
            }
        }

        @include break(xlarge){
            &.xlarge{
                display:block;
            }
        }

        @include break(xxlarge){
            &.xxlarge{
                display:block;
            }
        }
    }

} @else {
    .responsive-helper{
        display:none !important;
    }
}
/* ++++++++++++++++++++++++
+++ LAYOUT: BREADCRUMBS +++
++++++++++++++++++++++++ */

.breadcrumbs {
	background-color: $grey30;
	color: $grey30;
	font-size: 1rem;
	margin-top: 0;
	padding: $gap 0;

	&-list {
		display: block;
		color: $white;

		&-item {
			display: inline-block;
			margin-top: 0;
		}

		&-link {
			@extend %transition;
			font-weight: $bold;

			&:hover {
				color: $secondary;
			}
		}

		&-divider {
			color: $grey70;
			margin: 0;
		}
	}
}

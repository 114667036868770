.listing{

    &-image{
        display:none;
        @include flexbox(100%);
        min-height:550px;
        position:relative;

        @include break(medium){
            @include flexbox(51%);
        }

        .impact &{
            display:block;
        }

        &-credit{
			position:absolute;
			bottom:0;
			left:0;
			width: 100%;
			padding: 30px;
            background:rgba(black, 0.8);
            
            @include break(medium){
				width: 60%;
			}

			@include break(large){
				width: 70%;
			}

			p{
                color:white;
                @include font-size(14);

				&:before{
					content:'';
					height:15px;
					width:15px;
					background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 15V11' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 7H11.01' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
					margin-right: 8px;
					margin-top:-2px;
				}
			}

			a{
				text-decoration:underline;
			}
		}

        img{
            @extend %object-fit;
        }
    }

    &-impact{

        &-header{
            display:none;

            .impact &{
                display:block;
                color:$secondary;
                text-transform:uppercase;
                letter-spacing:2px;

                h2{
                    @include font-size(22);
                }
            }
        }

        &-link{
            display:none;

            .impact &{
                display:block;
                margin-top:$gap * 2.5;
            }
        }
    }

    .impact &{

        @media screen and (max-width:map-get($breakpoints, medium)) {
            padding:0;

            .container{
                padding:0;
            }
        }

        .section-header{
            display:none;
        }

        footer{
            display:none;
        }

        > div.container{
            @extend %flex;
        }
    }

    &-wrapper{

        .impact &{
            @include flexbox(100%);
            border-top:solid 10px $secondary;
            background:white;
            align-self:center;
            padding:$gap * 2;       
            z-index:1;

            @include break(medium){
                @include flexbox(51%);
                margin-left:-2%;
                box-shadow:$shadow;
            }
        }
    }

}
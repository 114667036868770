/* +++++++++++++++++++
+++ LAYOUT: SEARCH +++
+++++++++++++++++++ */

// Search bar is in the header and footer, can be styled for light and dark backgrounds

.search {
    display: inline-block;
    position: relative;
    vertical-align: middle;

    &-input {
        margin: 0;
        padding-right: 44px;
    }

    &-button {
        @extend %transition;
        border-radius: $corner;
        cursor: pointer;
        margin: $gap / 4;
        overflow: hidden;
        padding: $gap / 2;
        position: absolute;
        right: 0;
        top: 28%;

        &:hover {
            background: $primary;
            color: $white;
        }

        .icon {
            display: block;
            height: $gap;
            width: $gap;
        }
    }

    @include break(small) {
        .footer & {
            display: inline-block;
        }
    }
}

@if $drupal {
	#search-block-form {
		display: none;
		position: relative;
		width: 250px;

	    @include break(medium) {
	        display: inline-block;
	    }

  		.form-search {
  			@extend .search-input;
  		}

	    .form-actions {
			  top: 9px;
		    height: 25px;
		    position: absolute;
		    right: 9px;
		    width: 25px;

			.form-submit {
				    background: transparent;
		        border: none;
		        cursor: pointer;
		        height: 25px;
		        left: 0;
		        overflow: hidden;
		        padding: 25px 0 0 0;
		        position: absolute;
		        text-align: right;
		        top: 0;
		        width: 25px;
			   }
	    }
	}
}

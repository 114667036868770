//+++++++++++++++++++++++++++
//+++ HELPERS: TYPOGRAPHY +++
//+++++++++++++++++++++++++++

//=======================================
// Helpers
//=======================================


// This is the highlight colour used with the cursor
@include selection($background: $secondary, $color: $white);

// Form placeholder color
@include placeholder {
    color: currentColor;
    opacity: 0.4;
}


//=======================================
// Defaults
//=======================================

%font-header {
    font: $bold 1em/1.2 $font-header;
}

%font-body {
    font: $normal 1em/1.5 $font-body;
}

%font-content {
    font: $normal 1em/1.5 $font-content;
}

%font-meta {
    font: $normal 1em/1.5 $font-meta;
}

%font-label {
    font: $bold 0.9em/1.5 $font-header;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

//=======================================
// Settings
//=======================================

%h1 {
    @extend %font-header;
    @include font-size(32);
    color:$primary;

    @include break(large) {
        @include font-size(36);
    }
}

%h2 {
    @extend %font-header;
    @include font-size(28);
    color:$primary;

    @include break(large) {
        @include font-size(30);
    }
}

%h3 {
    @extend %font-header;
    @include font-size(22);
    color:$primary;

    @include break(large) {
        @include font-size(24);
    }
}

%h4 {
    @extend %font-header;
    @include font-size(20);
    color:$primary;

    @include break(large) {
        @include font-size(22);
    }
}

%h5 {
    @extend %font-header;
    @include font-size(18);
    color:$primary;

    @include break(large) {
        @include font-size(20);
    }
}

%h6 {
    @extend %font-header;
    @include font-size(16);
    color:$primary;

    @include break(large) {
        @include font-size(18);
    }
}

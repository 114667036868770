.donations{
  position:relative;
  background:$primary;
  padding:0;

  @include break(medium){
    padding:$gap * 3 0;
  }

  &-image{
    @extend %object-fit;
    min-height:180px;

    @include break(small){
      min-height:350px;
    }

    @include break(medium){
      position:absolute;
      top:0;
      left:0;
    }
    
  }

  form{
    padding:$gap * 1;
    max-width:100%;
    background:white;
    margin:-$gap * 1 auto $gap auto;
    box-shadow:$shadow;
    border-top:solid 10px $green;

    @include break(small){
      padding:$gap * 2;
      margin:-$gap * 2 auto $gap * 2 auto;
    }

    @include break(medium){
      max-width:450px;
      margin:$gap * 3 0 $gap * 3 0;
    }
  }

  h2{
    text-transform:uppercase;
    text-align:center;

    @include break(small){
      text-align:left;
    }

  }

  &-actions{
    @extend %flex;
    justify-content: space-between;
    margin-top:$gap;

    input, button{
      @include flexbox(100%);
      margin:0;

      & + button{
        margin-top:10px;
      }

      @include break(small){
        @include flexbox(48%);

        & + button{
          margin-top:0px;
        }

      }
    }

    button{
      border-radius:0px;
      color:white;
    }
  }

  &-amount{
    margin:0;
    flex:1;

    input:checked{
      + span{
        background:$primary;
        color:white;
        border-color:$primary;
  
        &:after{
          content:'';
          height:10px;
          width:10px;
          background:$primary;
          position:absolute;
          bottom:-5px;
          left:calc(50% - 5px);
          transform:rotate(45deg);
          // border:solid $grey;
          // border-width:0 1px 1px 0;
        }
  
      }
    }

    
    span{
      cursor:pointer;
      @extend %flex;
      @include font-size(20);
      align-items:center;
      justify-content:center;
      font-weight:$bold;
      font-family:$font-header;
      color:$primary;
      height:80px;
      border:solid 1px $grey;
      position:relative;

      @include break(small){
        height:100px;
      }
    }

  }

  .amount-tabpanel{
      padding:$gap;
      background:$grey90;
      margin-top:$gap;

      p{
        @include font-size(14);
      }
  }

  .tabs {
    @extend %flex;
  }
  
  [role="tablist"] {
    @extend %flex;  
    width:100%;
    justify-content: space-between; 
    
    &.frequency-tablist{
      background:$grey90;
      padding:10px;

      &.both{
        margin-top:$gap;
      }
    }

    button{
      margin:0;
      color:#707070;
      background:$white;
      @include flexbox(100%);

      & + button{
        margin-top:10px;
      }

      @include break(small){
        @include flexbox(48%);

        & + button{
          margin-top:0px;
        }
      }
    }
  }
  
  button[aria-selected="true"] {
    background: $secondary;
    color:white;
  }

  button:focus{
    outline:none;
    box-shadow:inset 0px 0px 1px 2px $secondary-dark;
  }

  .frequency{

    &-tab{
      &:only-child{
        display:none;
      }
    }

    &-tabpanel{
      margin-top:$gap;
    }
  }
  
}
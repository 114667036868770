/* +++++++++++++++++
+++ LAYOUT: PRESS +++
+++++++++++++++++ */

.contact-cards {
	.cards {
		justify-content: center;
		margin: 0;
		width: 100%;
	}
	.card {
		flex-direction: row-reverse;
		position: relative;
		
		&-header-title {
			font-size: 1.4em;
			border: 0;
			
			span {
				background: white;
				color: $primary;
				padding-bottom: 0;
				text-transform: none;
				letter-spacing: 0;
				line-height: 1.1;
			}
		}
		&-image {
			@include flexbox(30%);
			border-right: solid 10px $secondary;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
		}
		&-header {
			width: auto;
		}
		&-wrap, &-header {
			@include flexbox(70%);
			height: auto;
			margin: 0;
			order: -1;
		}
		&.hovered {
			.card-header {
				background-color: transparent;
			}
		}
	}
	.card-contact-info {
		margin: 0;
		
		p {
			margin-top: $gap / 2;
			
			a {
				@extend %text-hover;
				text-decoration: underline;
			}
			
			&:first-child {
				font-weight: $bold;
				margin-top: 0;	
			}
		}
	}	
}

.hero {
	.pr-single & {
		background: white;
		border-top: solid 10px $secondary;
		box-shadow: $shadow;
		min-height: auto;

		@include break(tablet) {
			justify-content: normal;
			flex-direction: row-reverse;
			margin: 0 $gap * 2 $gap * 1.5;
		}
		@include break(xlarge) {
			margin: 0 auto $gap * 1.5;
			width: $page-width;
		}
	}
	
	.hero-article-info-download {
		@include break(medium) {
			bottom: 0;
			position: absolute;
			right: 0;
		}
	}
	
	&-outer {
		.pr-single & {

			@include break(medium) {
				padding-top: $gap * 2;
			}
		}
	}

	&-image {
		.pr-single & {
			@include flexbox(100%);
			height: auto;
			margin: 0;
			overflow: hidden;
			position: relative;
			
			@include break(tablet) {
				@include flexbox(50%);
			}

			@include break(medium) {
				@include flexbox(30%);
			}
			img {
				@include break(tablet) {
					position: absolute;	
				}
			}
		}
	}

	&-info {
		.pr-single & {
			@include flexbox(100%);
			padding: 0 $gap * 2;
			margin: 0;

			@include break(tablet) {
				@include flexbox(50%);
				padding: 0 $gap * 2;
			}

			@include break(medium) {
				@include flexbox(70%);
				padding: 0;
				margin: 0;
				z-index: 2;
			}
		}

	}

	&-inner {
		.pr-single & {
			background-color: transparent;
			border-top: 0;
			box-shadow: none;
			max-width: none;
			padding: $gap * 2 0;
			
			@include break(medium) {
				padding: $gap * 2;	
			}
		}
	}

	&-title {
		.pr-single & {
			text-transform: none;
			line-height: 1;
			@include font-size(30);
			letter-spacing: -1.5px;

			@include break(large) {
				@include font-size(36);
			}
		}
	}
	&-summary {
		.pr-single & {
			max-width: 100%;
		}
	}
}
/* ++++++++++++++++++
+++ LAYOUT: CARDS +++
++++++++++++++++++ */

// Cards are more visually interesting and content-light than a standard listing page.

.cards {
	@extend %flex;
	position: relative;
	width: calc(100% + #{$gap * 2});
	margin: 0 (-$gap * 1);

	>* {
		@extend %flex;
		@include flexbox(100%);
		margin: $gap * 1.5 0;

		.card {
			margin: 0 $gap * 1;

			.impact .listing & {
				margin: 0;
			}
		}

		.listing & {
			margin: $gap * 1 0 0 0;

			@include break(small) {
				margin: $gap * 1.5 0;
			}

			.impact & {
				margin: $gap * 2 0 0 0;
			}
		}
	}

	@include break(tablet) {
		>* {
			@include flexbox(50%);

			.news-listing & {
				@include flexbox(33.33%);
			}

			.impact .listing & {
				@include flexbox(100%);
			}
		}
	}

	@include break(large) {

		width: calc(100% + #{$gap * 1.6});
		margin: 0 (-$gap * 0.8);

		article {

			@include bycount(3, article) {

				@include flexbox(25%);

				&:nth-of-type(2) {
					@include flexbox(50%);

					.card-image {
						height: 300px;
					}
				}

				.card {
					margin: 0 $gap * 0.8;

					.impact .listing & {
						margin: 0;
					}
				}

				.news-single &,
				.news-listing &,
				.page-builder &,
				.pr-single &{
					@include flexbox(33.33%);

					&:nth-of-type(2) {
						@include flexbox(33.33%);

						.card-image {
							height: 250px;
						}
					}

				}

				.resources-listing & {
					@include flexbox(50%);

					&:nth-of-type(2) {
						@include flexbox(50%);
					}
				}

				.impact .listing & {
					@include flexbox(100%);
				}

			}

			@include bycount(4, article) {

				@include flexbox(25%);

				&:nth-of-type(2) {
					@include flexbox(25%);

					.card-image {
						height: 250px;
					}
				}

				.news-single &,
				.page-builder & {
					@include flexbox(25%);

					&:nth-of-type(2) {
						@include flexbox(25%);
					}
				}

				.listing & {
					@include flexbox(50%);

					&:nth-of-type(2) {
						@include flexbox(50%);
					}

					.impact & {
						@include flexbox(100%);
					}
				}

				.region-cards & {
					@include flexbox(33.33%);

					&:nth-of-type(2) {
						@include flexbox(33.33%);

						.card-image {
							height: 250px;
						}
					}

				}

				.resources-listing & {
					@include flexbox(50%);
				}

				.card {
					margin: 0 $gap * 0.8;

					.impact .listing & {
						margin: 0;
					}
				}
			}

		}
	}

	&-outer {
		.section-header {

			&-title {

				.home & {
					@include font-size(60);
					color: $secondary;
					text-transform: uppercase;
					text-align: left;
					letter-spacing: -1px;
					padding: 0 10px;
					line-height: 1;

					@include break(small) {
						@include font-size(80);
						letter-spacing: -3px;
					}

					@include break(medium) {
						@include font-size(100);
						letter-spacing: -5px;
					}

					@include break(large) {
						@include font-size(144);
					}
				}
			}

			&.container {
				@include break(medium) {
					padding: 0 $gap * 2;
				}
			}
		}

		&.resources {

			.resources-listing & {
				background: white;
				padding: $gap * 1 0;

				&.featured-resources {
					background: $grey90;

				}
			}
		}

		.news-single & {
			&:not(.resources) {

				.section-header-title {
					@include font-size(40);
					text-transform: uppercase;
					color: $primary;

					@include break(tablet) {
						@include font-size(70);
					}

					@include break(medium) {
						@include font-size(120);
					}
				}

				.section-header-summary {
					display: none;
				}
			}
		}
	}

	.listing & {
		.impact & {
			margin: 0;
			width: 100%;
		}
	}

}

.card {
	@extend %card;
	@extend %flex;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
	transition: ease 0.3s;

	&-image {
		display: block;
		margin: 0;
		position: relative;
		width: 100%;
		height: 250px;

		img {
			@extend %object-fit;
		}

		.news-single &,
		.news-listing &,
		.page-builder &,
		.news-listing,
		.pr-single & {
			order: -1;
		}

		.resources & {
			width: 180px;
		}
	}

	&-wrap {
		align-self: stretch;
		height: 100%;
		margin: 0;
		flex: 1;

		&-inner {
			height: 100%;
			padding: 15px 20px 20px 20px;

			.resources & {
				flex: 1;
				padding: 20px 0 0 0;

				@include break(small) {
					padding: 20px;
				}

				@include break(tablet) {
					padding: 20px 0 0 0;
					flex: 100%;
				}

				@include break(medium) {
					flex: 1;
					padding: 20px;
				}
			}

			.listing & {
				padding: 20px 0;

				@include break(tablet) {
					padding: 20px;
				}

				.impact & {
					padding: 0;
				}
			}
		}

		.resources & {
			padding: 20px;
			@extend %flex;
			flex-direction: column;

			@include break(small) {
				flex-direction: row;
			}
		}
	}

	&-header {
		margin-top: 0;
		text-align: left;
		width: 100%;
		transition: ease 0.3s;

		&-title {
			@include font-size(20);

			.news-single &,
			.news-listing &,
			.page-builder &,
			.pr-single & {
				border-top: solid 10px $secondary;
				background: white;
			}

			span {
				display: block;
				background: $primary;
				transition: ease 0.3s;
				color: white;
				letter-spacing: 2px;
				text-transform: uppercase;
				padding: 20px;

				.news-single &,
				.news-listing &,
				.page-builder &,
				.listing &,
				.resources &,
				.pr-single & {
					background: white;
					color: $primary;
					padding-bottom: 0;
					text-transform: none;
					@include font-size(28);
					letter-spacing: 0;
					line-height: 1.1;
				}

				.listing &,
				.resources & {
					padding: 0 0 20px 0;

					&:hover {
						text-decoration: underline;
					}

				}

				.listing & {
					.impact & {
						padding: 0;
						@include font-size(20);
						padding-left: 20px;
						border-left: 5px solid $grey;

						&:hover {
							border-color: $primary;
						}
					}
				}

				.resources & {
					&:hover {
						text-decoration: underline;
					}
				}

				.hovered & {
					background: $secondary;

					.news-single &,
					.news-listing &,
					.page-builder &,
					.listing &,
					.resources &,
					.pr-single & {
						background: white;
					}

					.resources & {
						text-decoration: none;
					}
				}
			}

			.listing & {
				border-top: 0;
			}

			.resources & {
				border: 0;
				margin-top: $gap / 2;
			}

		}

		&-date {
			display: block;
			padding: 15px 20px 0 20px;
			font-weight: $bold;
			text-transform: uppercase;
			color: $black;
			background: white;
			@include font-size(13);
		}

		.hovered & {
			background: $secondary;
		}
	}

	&-button {
		&-outer {
			margin-top: $gap;
		}
	}

	&-summary {
		.listing & {
			.impact & {
				display: none;
			}
		}
	}

	&-link {
		font-family: $font-header;
		color: $secondary;
		text-transform: uppercase;
		@include font-size(18);
		transition: ease 0.3s;
		box-shadow: inset 0px -3px 0px 0px transparent;

		&:hover,
		.hovered & {
			box-shadow: inset 0px -3px 0px 0px $secondary;

			.listing & {
				box-shadow: none;
			}
		}
	}

	&-meta {
		color: $primary;
		font-family: $font-header;
		text-transform: uppercase;
		@include font-size(16);
		font-weight: $bold;

		span {
			display: inline-block;
			line-height: 1;

			&:nth-of-type(2) {
				margin-left: 10px;
				padding-left: 10px;
				border-left: 3px solid $primary;
			}
		}
	}

	&-country{
		& + .card-country{
			margin-top:10px;
		}

		a:hover{
			.card-country-name{
				text-decoration:underline;
			}
		}
	}

	.metalabel {
		padding: 20px 20px 10px 20px;
		border-top: solid 10px $secondary;
		background: white;

		a {
			color: white;
			background: $primary;
			padding: 3px 10px;
			@include font-size(12);

			&:hover {
				background: $secondary;
			}
		}

		&+.card-header-title {
			border-top: 0;

			a {
				padding-top: 0;
			}
		}
	}

	&.featured {
		position: relative;
		overflow: visible;

		&:before {
			content: 'FEATURED';
			position: absolute;
			top: 15px;
			left: -10px;
			z-index: 10;
			background: $primary;
			color: white;
			padding: 3px 10px;
			font-weight: bold;
			font-family: $font-header;
			@include font-size(13);

			.resources & {
				top: 25px;
			}
		}

		&:after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			border-top: 10px solid darken($primary, 10%);
			border-left: 10px solid transparent;
			top: 40px;
			left: -10px;

			.resources & {
				top: 50px;
			}
		}
	}

	// card variations

	.listing & {
		box-shadow: none;
	}

	.resources & {
		border-top: solid 10px $secondary;
	}

	&.hovered {
		box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.4);

		.listing & {
			box-shadow: none;
		}
	}
}
/* ++++++++++++++++++++
+++ LAYOUT: SIDEBAR +++
++++++++++++++++++++ */

// Represents content that is tangentially related to the content nearby, such as tags and subnavigation. within the {% block page %} the sidebar is optional and if included will trigger a two column layout

.sidebar {
	@extend %transition;
	background-color: $white;
	margin-bottom: $gap;
	width: 100%;
	z-index: 1000;

	@include break(medium) {
		@include flexbox(27%);
		margin: -2em 0 0;
		float: right;
		margin: 0;
		position: sticky;
		position: -webkit-sticky;
		top: 110px;


		.nav-up & {
			top: $gap * 2;
		}
	}

	@include break(large) {
		@include flexbox(25%);
	}

	&-block+&-block {
		margin-top: $gap * 3;
	}

	&-title {
		font: $bold 1.2em/1.2 $font-header;
		margin: 0;
		text-transform: uppercase;
		background: $primary;
		color: white;
		margin: 0;
		padding: 15px;
	}

	&-block {
		position: relative;
		box-shadow: $shadow;
		margin-bottom: $gap;

		&.expand {
			&:before {
				content: '';
				display: none;
				height: 30px;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 2;

				@include break(medium) {
					display: block;
				}
			}
		}

		.icon {
			position: absolute;
			right: 15px;
			top: 12px;
			color: white;
			@include font-size(20);

			@include break(medium) {
				display: none;
			}
		}

		.expand-check:checked~.expand-trigger .icon {
			transform: rotate(180deg);
		}

		.expand-check~div {
			display: none;

			@include break(medium) {
				display: block !important;
			}
		}
	}

	&-nav {

		&-item {
			padding: 0 0 $gap / 2;

			.subnav_element {
				@extend %text-hover;
				display: block;
				font-weight: $bold;

				&:hover,
				&.current {
					color: $primary;
					font-weight: $bold;
				}
			}

			&.current-menu-item {
				.subnav_element {
					color: $secondary;
					font-weight: $bold;
				}

				.sidebar-nav-item {
					.subnav_element {
						color: inherit;
						font-weight: $normal;
					}
				}
			}

		}

		&-a11ybutton {
			border-radius: 0px;
			height: 20px;
			width: 20px;
			padding: 0;
			position: absolute;
			margin: 0;
			top: 13px;
			right: 15px;
			background: transparent;
			pointer-events: none;
			box-shadow: none;

			&:hover {
				background: transparent;
			}
		}


		>.sidebar-nav-list {
			>.sidebar-nav-item {

				padding: 10px 40px 10px 15px;
				margin: 0;
				position: relative;


				&.has-children {

					a {
						position: relative;
						z-index: 1;
					}

					&:after {
						content: '';
						position: absolute;
						height: 20px;
						width: 20px;
						background-image: url(../images/menu-arrow.svg);
						background-size: cover;
						right: 15px;
						top: 13px;
						transition: ease 0.3s;
						pointer-events: none;
					}

					&:before {
						content: '';
						height: 44px;
						width: 100%;
						position: absolute;
						cursor: pointer;
						top: 0;
						left: 0;
						z-index: 0;
					}

					&.open {
						.sub-list {
							box-shadow: inset 2px 0 #c1c1c1;
						}

						&:after {
							transform: rotate(180deg);
						}
					}
				}

				>.sidebar-nav-item-link {
					display: inline;
					font-size: 16px;
					font-weight: $bold;
				}

				>.sub-list {
					display: none;
					margin-bottom: 10px;
				}

				&:nth-of-type(even) {
					background: rgba($primary, 0.1);
				}

			}
		}

	}

	&-meta {
		margin: 0;
		position: relative;

		&-block {
			padding: $gap / 2 0;

			span {
				color: $secondary;
				font-weight: $bold;
			}

			&-tag {
				@extend %transition;
				background: $white;
				border-radius: $corner;
				color: $grey40;
				display: inline-block;
				font: $bold 0.9em/1.5 $font-body;
				margin: 0 $gap / 2;
				padding: $gap / 4 $gap / 2;

				&:hover {
					background: $secondary;
					color: $white;
				}
			}
		}

		+.sidebar-img {
			img {
				padding-top: 0;
			}
		}

		+.sidebar-button {
			margin-top: $gap;
		}
	}

	&-socials {

		display: inline-block;
		width: auto;
		background: white;

		@include break(small) {
			margin-bottom: $gap;
		}

		@include break(medium) {
			position: relative;
		}

		@include break(xlarge) {
			width: 50px;
			position: fixed;
			left: -50px;
			// left:0;
			top: 60%;
			transform: translateY(-50%);
			transition: ease 0.3s;

			&.active {
				left: 0;
			}
		}

		ul {
			box-shadow: $shadow;
		}

		li {
			display: inline-block;
			vertical-align: middle;
			height: 50px;
			width: 50px;
			transition: ease 0.3s;

			&.share {
				background: $primary;
				color: white;
				padding: 10px;

				svg {
					height: 30px;
					width: 30px;
					padding: 3px;
				}
			}

			a {
				height: 50px;
				width: 50px;
				padding: 10px;
				display: block;
				color: $primary;

				svg {
					height: 30px;
					width: 30px;
				}

				&.whatsapp {

					svg {
						padding: 3px;
					}
				}
			}

			&:hover:not(.share) {
				background: $secondary;

				a {
					color: white;
				}
			}

			&:nth-of-type(even):not(.share) {
				background: rgba($primary, 0.1);

				&:hover {
					background: $secondary;
				}
			}
		}
	}

	.inline-group {
		+.sidebar-button {
			margin-top: $gap;
		}
	}

	&-downloads {
		margin-top: $gap;

		&-block {
			background-color: $white;
			color: $black;
			padding: $gap;
			width: 100%;

			.icon {
				display: inline-block;
				margin: 0 $gap / 3 0 0;
				width: 20px;
			}

			h3 {
				color: $primary;
				font-size: 1.2em;
			}

			a {
				@extend %transition;
				display: block;
				font-size: 0.9em;
				padding: $gap / 2 0 0 $gap * 2;
				position: relative;

				&:hover {
					color: $primary;
				}

				.icon {
					left: 0;
					position: absolute;
					top: $gap / 2;
				}
			}
		}
	}

	&-act {

		display: inline-block;

		@include break(medium) {
			display: block;
		}

		a {
			padding: 13px;
			display: inline-block;
			vertical-align: middle;
			margin: 0;
			background: $orange;
			color: white;
			border-radius: 100%;
			transition: ease 0.3s;

			@include break(medium) {
				width: 50px;
				height: 50px;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&:hover {
				background: $secondary;
			}
		}

		p {
			text-transform: uppercase;
			font-family: $font-header;
			font-weight: bold;
			color: $primary;
			display: inline-block;
			vertical-align: middle;
			margin-left: $gap / 2;

			@include break(medium) {
				transform: rotate(-90deg);
				margin-left: 0;
				display: table;
				margin: 0;
				padding: 15px 10px;
			}
		}

		&+.sidebar-share {
			margin: 0 0 0 $gap;

			@include break(medium) {
				margin: $gap 0 0 0;
			}
		}

	}

	&-donate {
		display: inline-block;

		@include break(medium) {
			display: block;
		}

		a {
			padding: 13px;
			display: inline-block;
			vertical-align: middle;
			margin: 0;
			background: $orange;
			color: white;
			border-radius: 100%;
			transition: ease 0.3s;

			@include break(medium) {
				width: 50px;
				height: 50px;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&:hover {
				background: $secondary;
			}
		}

		p {
			text-transform: uppercase;
			font-family: $font-header;
			font-weight: bold;
			color: $primary;
			display: inline-block;
			vertical-align: middle;
			margin-left: $gap / 2;

			@include break(medium) {
				transform: rotate(-90deg);
				margin-left: 0;
				display: table;
				margin: 0;
				padding: 0px 25px 65px 25px;
			}
		}

		&+.sidebar-share {
			margin: 0 0 0 $gap;

			@include break(medium) {
				margin: $gap 0 0 0;
			}
		}

		.icon {
			width: 1.8em;
			height: 1.8em;
			margin-top: 2px;
		}
	}

	&-share {

		display: inline-block;


		@include break(medium) {
			display: block;
		}

		li {
			display: inline-block;
			vertical-align: middle;

			@include break(medium) {
				display: block;
			}
		}

		li+li {
			margin: 0 0 0 10px;

			@include break(medium) {
				margin: 10px 0 0 0;
			}
		}

		a {
			width: 50px;
			height: 50px;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			transition: ease 0.3s;

			&.twitter {
				background: $twitter;
			}

			&.facebook {
				background: $facebook;
			}
			
			&.linkedin {
				background: $linkedin;
			}

			&:hover {
				background: $secondary;
			}

		}

		p {
			text-transform: uppercase;
			font-family: $font-header;
			font-weight: bold;
			color: $primary;
			display: inline-block;
			margin-left: $gap / 2;

			@include break(medium) {
				transform: rotate(-90deg);
				display: table;
				margin: 0;
				left: 0;
				padding: 30px 0px;
			}
		}
	}

	&-resources {

		&-meta {

			@include break(tablet) {
				display: inline-block;
				vertical-align: top;
				margin-left: $gap * 1.5;
			}

			@include break(medium) {
				display: block;
				margin-left: 0;
			}
		}

		&-image {

			width: 200px;
			height: 270px;
			margin: 0;
			display: inline-block;

			@include break(medium) {
				height: 30vw;
				max-height: 370px;
				width: 100%;
			}

			img {
				@extend %object-fit;
			}
		}

		&-tags {
			margin-top: $gap / 2;

			.tag {
				display: inline-block;
				background: $blue;
				padding: 4px 5px;
				color: White;
				text-transform: uppercase;
				margin-top: 10px;
				font-family: $font-header;
				font-weight: $bold;
				@include font-size(12);
				transition: ease 0.3s;
				margin-right: 5px;

				&:hover {
					background: $secondary;
				}
			}
		}

		&-dl {
			margin-top: $gap;
		}

		&-authors,
		&-date {
			margin-top: $gap;

			p {
				color: $primary;
				font-weight: $bold;
				margin-bottom: 5px;
			}

			.author {
				color: black;
				@include font-size(14);
				display: inline-block;

				&:after {
					content: ',';
					margin-right: 5px;
				}

				&:last-of-type:after {
					display: none;
				}

				@include break(medium) {
					display: block;

					&:after {
						display: none;
					}
				}
			}

			.published_date {
				color: black;
				@include font-size(14);
				display: inline-block;

			}
		}

		&-authors {
			margin-top: $gap;

			@include break(tablet) {
				margin-top: 0;
			}

			@include break(medium) {
				margin-top: $gap;
			}
		}
	}

	.news-single & {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1000;
		background: $grey95;
		margin-bottom: 0;
		padding: 10px;
		box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);

		@include break(medium) {
			background: transparent;
			position: sticky;
			padding: 0;
			box-shadow: none;
		}
	}

	.resource-single & {
		background: white;
		padding: $gap * 1.5;
		box-shadow: $shadow;
		border-top: solid 10px $secondary;
		margin-top: -80px;

		@include break(medium) {
			margin-top: -150px;
		}
	}
}

.sub-list {
	box-shadow: inset 2px 0 $grey90;
	margin: $gap 0 0;

	& & {
		margin-left: $gap;
	}

	.sidebar-nav-item-link {
		display: block;
		font-weight: $normal;

		&.current {
			box-shadow: inset 2px 0 currentColor;
			color: $primary;
			margin-left: -$gap;
			padding-left: $gap;
			font-weight: $bold;
		}
	}

	.sidebar-nav-item {
		padding-left: $gap;

		&.current-menu-item {
			.sidebar-nav-item-link {
				box-shadow: inset 2px 0 currentColor;
			}

			.sidebar-nav-item {
				.sidebar-nav-item-link {
					box-shadow: none;
				}
			}
		}
	}
}
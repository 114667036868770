/* ++++++++++++++++
+++ LAYOUT: CTA +++
++++++++++++++++ */

.cta {
	color: $white;
	position: relative;

	&-outer {
		background-color: $white;
		position: relative;
		overflow: visible;

		&:not(.no-image) {
			@media screen and (max-width: map-get($breakpoints, medium)) {
				padding-top: 0;
			}

			// .impact &{
			// 	background:$grey90;

			// 	@include break(medium){
			// 		padding:0;
			// 	}
			// }

		}

		&+.cta-outer:not(.no-image) {
			padding-top: 0;
		}

		.components & {
			&:not(.no-image):first-child {
				padding-top: 0;
			}
		}

		&.flipped {
			.container {
				flex-direction: row-reverse;
			}
		}

		&.no-image {
			background: $primary;
			text-align: center;
			color: white;

			.news-single & {
				padding-top: 0;
				background: transparent;
				text-align: left;

				.container {
					max-width:#{$page-width - 10em};
				}
			}
		}

		@include break(medium) {

			.home & {
				&.first {
					padding-top: 0;
					padding-bottom: 0;
					z-index: 1;

					.cta-image {
						margin-bottom: -$gap * 2;
					}
				}

				&.second {
					padding-top: 0;
					z-index: 1;

					.cta-image {
						margin-top: -$gap * 2;
					}
				}
			}

		}

		.container {
			@extend %flex;
			align-items: center;
		}

	}

	&-summary {
		padding: $gap * 1 0 $gap * 1.5;

		.no-image & {
			max-width: 500px;

			.news-single & {
				margin-left: 0;
			}
		}
	}

	&-image {
		flex: 1 90%;
		max-height: 900px;
		min-height: 500px;
		margin-left: -$gap;
		margin-right: -$gap;
		position: relative;

		@include break(small) {
			margin-left: -$gap * 2;
			margin-right: -$gap * 2;
		}

		@include break(medium) {
			flex: 1 60%;
			margin-right: 0;
			margin-left: -$gap * 2;

			.flipped & {
				margin-left: 0;
				margin-right: -$gap * 2;
			}
		}

		img {
			@extend %object-fit;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&-title {
		color: $primary;
		@include font-size(36);
		line-height: 1;

		@include break(tablet) {
			@include font-size(48);
		}

		span {
			color: $secondary;
			text-transform: uppercase;
			@include font-size(18);
			letter-spacing: 2px;
			display: block;
			margin-bottom: $gap;
		}

		.no-image & {
			color: white;

			.news-single & {
				@include font-size(40);
			}
		}
	}

	&-container {
		flex: 1 100%;
		background: $white;
		border-top: solid 10px $secondary;
		padding: $gap * 2 $gap * 3 $gap * 2 $gap * 1.5;
		box-shadow: $shadow;
		margin-top: -$gap * 2;
		z-index: 1;

		.no-image & {
			box-shadow: none;
			padding: 0;
			margin: 0;
			border-top: 0;
			text-align: center;
			background: transparent;
			color: white;

			.news-single & {
				background: $primary;
				max-width: 100%;
				border-top: solid 10px $green;
				padding: 120px $gap * 2 $gap * 2 $gap * 2;
				text-align: left;
				position: relative;

				@include break(small) {
					padding: $gap * 2 $gap * 2 $gap * 2 100px;
				}

				@include break(tablet) {
					padding: $gap * 2 $gap * 2 $gap * 2 120px;
				}

				@include break(medium) {
					max-width: 70%;
					padding: $gap * 3 $gap * 3 $gap * 3 160px;
				}

				&:before {
					content: '';
					position: absolute;
					background-image: url(../images/logomark.svg);
					background-position: center;
					background-size: cover;
					display: block;
					width: 40px;
					height: 78px;
					left: 33px;
					top: 1em;

					@include break(small) {
						top: 2em;
					}

					@include break(tablet) {
						width: 60px;
						height: 116px;
					}

					@include break(medium) {
						left: 40px;
						top: 3em;
						width: 80px;
						height: 155px;
					}
				}
			}
		}

		@include break(medium) {
			flex: 1 40%;
			margin-top: 0;
			margin-left: -$gap * 3;
			margin-right: $gap * 2;

			.flipped & {
				margin-left: $gap * 2;
				margin-right: -$gap * 3;
			}

			.no-image & {
				margin: 0;
			}
		}

	}

}
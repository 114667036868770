/* +++++++++++++++++++++++
+++ LAYOUT: STATISTICS +++
+++++++++++++++++++++++ */

.statistic {
	@extend %flex;
	padding: 0 $gap * 2;
	justify-content: space-between;
	text-align: center;
	
    @include break(large) {
	    padding: 0;
	}
	
	&-outer{
		background:$primary;
		color:white;
		padding:$gap * 4 0;
		position:relative;
		
		@include break(medium){
			padding:$gap * 6 0;
		}

		.section-header-title{
			font-family:$font-body;
			font-weight:$light;
			text-transform:uppercase;
			letter-spacing:10px;
			@include font-size(25);
			margin-bottom:$gap * 1;
			color:white;

			@include break(medium){
				@include font-size(36);
			}

			&:before{
				content:'';
				width: 66px;
				height: 128px;
				display:block;
				margin:auto auto 10px auto;
				background-image:url('../images/logomark.svg');
				background-position:center;
				background-size:cover;
			}
		}

		&:after{
			content:'';
			position:absolute;
			height:100%;
			width:100%;
			top:0%;
			left:0%;
			margin:auto auto 10px auto;
			background-image:url('../images/stats-background.webp');
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			z-index:0;
			opacity:0.3;

			@include break(small){
				background-size: contain;
				height:80%;
				width:80%;
				top:20%;
				left:10%;
				opacity:0.7;
			}
		}

		.container{
			z-index:1;
		}

		.button.green{
			&:hover{
				background:$orange;
				color:white;
			}
		}
	}
    
    &-item {
    	@include flexbox(100%);
    	margin: 0 0 $gap * 4;
    	

    	@include break(medium) {
			margin: 0 0 $gap * 2;
	    	@include flexbox(100%);
			margin: 0;
	    	
		    // &:first-child:nth-last-child(2), &:first-child:nth-last-child(2) ~ .statistic-item {
	        //     @include flexbox(48%);
		    // }
		    // &:first-child:nth-last-child(3), &:first-child:nth-last-child(3) ~ .statistic-item {
	        //     @include flexbox(30%);
			// }

			@include bycount(2, li){

				@include flexbox(50%);

			}
			
			@include bycount(3, li){

				@include flexbox(50%);

				.statistic-summary{
					width:70%;
				}

				&:first-of-type{
					@include flexbox(100%);
					margin-bottom:$gap * 3;

					.statistic-summary{
						width:40%;
					}
				}
			}

    	}
    	&:last-child {
	    	margin-bottom: $gap * 2;
	    	
	    	@include break(small) {
	    		margin: 0 0 $gap * 2;
	    	}
    	}
	}
	
	&-summary{
		width:100%;

		@include break(tablet){
			width:50%;
		}
	}

    &-icon {
        font-size: 2em;
    }

    &-value {
	    display: inline-block;
	    padding: 0 0 $gap / 2;
	    text-align: center;
	    
        &-number {
			@extend %h1;
			font-weight: $bold;
			letter-spacing:-2px;
			@include font-size(50);
			text-transform:uppercase;
			line-height:1;
			color:white;

			@include break(small){
				letter-spacing:-5px;
			}

			@include break(tablet){
				@include font-size(80);
			}

			@include break(medium){
				@include font-size(120);
			}

			@include break(large){
				@include font-size(144);
			}
        }
	}
	
    &-title {
		font-size: 1.6em;
		padding: 1.5em 0 1em;
		position: relative;
	}
	
}

/* +++++++++++++++++
+++ LAYOUT: HERO +++
+++++++++++++++++ */

.hero {

	position: relative;
	@extend %flex;
	justify-content: center;
	background: $primary;

	.hero-globe &{
		min-height:1000px;
	}

	@include break(tablet) {
		flex-direction: column;
	}

	@include break(medium) {
		min-height: 700px;

		.no-image & {
			min-height: auto;
		}

		.impact & {
			min-height: 500px;
		}

		.hero-globe &{
			min-height:800px;
		}
	}

	.news-single & {

		min-height: auto;

		@include break(medium) {
			background: transparent;
			flex-direction: row;
		}
	}

	.resource-single & {
		min-height: auto;
	}

	&-outer {
		padding: 0;

		.news-single & {

			@include break(medium) {
				padding-top: $gap * 2;
			}
		}
	}

	&-image {

		@include break(medium) {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}

		img {
			@extend %object-fit;
		}

		// .impact & {
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	height: 100%;
		// 	width: 100%;
		// }

		.news-single & {
			margin: 0;
			width: 100%;
			height: 400px;

			@include break(small) {
				height: 600px;
			}

			@include break(medium) {
				align-self: center;
				position: relative;
				height: 45vw;
				width: calc(60% + 50px);
				margin-left: -50px;
			}

			@include break(large) {
				height: 40vw;
				width: 50%;
			}

			@include break(xlarge) {
				height: 35vw;
				width: 45%;
			}

		}
	}

	&-info {

		margin: -$gap 0 $gap 0;

		.no-image & {
			margin: $gap 0 $gap 0;

			.resource-single & {
				margin: $gap * 3 0 $gap * 6 0;

				@include break(medium) {
					margin: $gap * 3 0 $gap * 3 0;
				}
			}
		}

		@include break(tablet) {
			margin: -$gap * 2 0 $gap * 2 0;

			.no-image & {
				margin: $gap * 2 0 $gap * 2 0;
			}
		}

		@include break(medium) {
			margin: 0;
			padding: $gap * 2 0;

			.resource-single & {
				padding: $gap * 3 0;
			}

			.impact & {
				margin: auto auto 0 auto;
				padding: $gap * 2 0;
			}
		}

		a {
			margin-top: $gap;

			&.popup-video {
				position: relative;
				padding: 0;
				box-shadow: none;
				color: $secondary;
				background: none;
				@extend %h5;
				text-transform: uppercase;
				margin: $gap * 1 0 0 0;
				display: table;

				span {
					padding: 5px 0;
				}

				&:before {
					content: '';
					height: 50px;
					width: 50px;
					margin-right: 10px;
					margin-top: -4px;
					background-color: $secondary;
					border-radius: 100%;
					display: inline-block;
					vertical-align: middle;
				}

				&:after {
					content: '';
					width: 0;
					height: 0;
					position: absolute;
					top: 9px;
					left: 19px;
					border-top: 12px solid transparent;
					border-left: 19px solid white;
					border-bottom: 12px solid transparent;
				}

				&:hover {
					span {
						box-shadow: inset 0px -5px 0px 0px $secondary;
					}
				}

			}

			.impact & {
				display: none;
			}
		}

		.news-single & {
			padding: 0 $gap * 1;
			margin: -$gap * 2 0 $gap * 2 0;

			@include break(small) {
				padding: 0 $gap * 2;
			}

			@include break(medium) {
				padding: 0;
				margin: 0 0 0 -17%;
				z-index: 2;
				align-self: center;
			}

			@include break(large) {
				margin-left: -10%
			}

			@include break(xlarge) {
				margin-left: -5%
			}
		}

	}

	&-inner {
		background: white;
		border-top: solid 10px $secondary;
		padding: $gap * 1;
		margin: 0;
		box-shadow: $shadow;

		@include break(tablet) {
			padding: $gap * 2;
		}

		@include break(medium) {
			max-width: 600px;

			.hero-globe &{
				max-width:500px;
			}
		}

		.news-single & {
			max-width: none;

			@include break(medium) {
				max-width: 500px;
			}

			@include break(large) {
				max-width: 600px;
			}
		}

		.resource-single & {
			background: transparent;
			border: none;
			box-shadow: none;
			padding: 0;
			color: white;

			h1 {
				color: white;
			}
		}
	}

	&-summary {
		margin-top: $gap;
		margin-left: 0;

		@include break(tablet) {
			max-width: 60%;
		}

		@include break(medium) {
			max-width: none;
		}

		.impact & {
			display: none;
		}

		.hero-globe &{
			p{
				@include font-size(20);
			}
		}
	}

	&-title {
		line-height: 0.9;
		text-transform: uppercase;
		color: $primary;
		@include font-size(35);
		word-break: break-word;

		@include break(medium) {
			@include font-size(48);
		}

		.impact & {
			text-align: center;
			color: $primary;
		}

		.news-single & {
			text-transform: none;
			line-height: 1;
			@include font-size(30);
			letter-spacing: -1.5px;

			@include break(large) {
				@include font-size(36);
			}
		}
	}

	&-campaign {
		position: relative;
		margin: 0;

		@include break(tablet) {
			padding: $gap * 10 0;
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 50%;
			height: 100%;
			background: rgb(0, 0, 0);
			background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
			background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
			background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
			z-index: 1;
		}
		.dec-logo {
			display: none;
		}
		
		&.dec {
			padding-top: 0;
			
			.dec-logo {
				background-color: #FF0000;
				display: block;
				left: $gap * 1.8;
				margin: 0 0 $gap * 3 -10px;
				padding: $gap / 1.5 $gap / 1.5 $gap* 1.5;
				position: absolute;
				top: -141px;
				width: 80px;
				
				@include break(tablet) {
					left: 0;
					padding: $gap $gap $gap* 2;
					position: relative;
					top: 0;
					width: 110px;
				}
			}
		}

		&-inner {
			width: 100%;
			margin-left: 0;

			@include break(small) {
				width: 70%;
			}

			@include break(tablet) {
				margin-left: 15px;
			}

			@include break(medium) {
				width: 60%;
			}

			@include break(large) {
				width: 50%;
			}
		}

		h1 {
			display: inline;
			width: 50%;
			@include font-size(45);

			@include break(medium) {
				@include font-size(52);
			}

			span {
				display: inline-block;
				width: 100%;

				span {
					display: inline;
					margin: 0;
					background: $white;
					box-shadow: 10px 0 0 $white, -10px 0 0 $white;
					box-decoration-break: clone;
				}

				&:first-of-type span {
					color: $primary;
				}

				&:nth-of-type(2) span {
					color: $secondary;
				}

				&:last-of-type span {
					color: black;
				}

			}

			text-transform:uppercase;
		}

		a {
			display: table;
			margin: $gap * 1 0 0 0;

			@include break(tablet) {
				margin: $gap * 2 0 0 -10px;
			}
		}

		.container {
			z-index: 1;

			@media screen and (max-width: map-get($breakpoints, tablet)) {
				background: white;
				padding: $gap * 2 $gap;
			}
		}

		.hero-image {
			top: 0;
			left: 0;
			height: 280px;
			width: 100%;
			z-index: 0;
			
			@include break(small) {
				height: 400px;
			}

			@include break(tablet) {
				position: absolute;
				height: 100%;
			}
		}

		img {
			@extend %object-fit;
		}

		&+.hero-info {
			background: $primary;
			padding: $gap * 2 0;
			color: white;
			border-top: solid 10px $secondary;
			margin-top: 0;

			@include break(tablet) {
				padding: $gap * 4 0;
			}

			.container {
				max-width: 70em;
			}

			.hero-inner {
				position: relative;
				max-width: none;
				background: transparent;
				padding: 0;
				box-shadow: none;
				border-top: 0;

				@include break(tablet) {
					padding-left: 100px;
				}

				@include break(large) {
					padding-left: 120px;
				}

				&:before {
					content: '';
					position: absolute;
					left: 0;
					display: none;
					margin: 0;
					background-image: url(../images/logomark.svg);
					background-position: center;
					background-size: cover;

					@include break(tablet) {
						display: block;
						top: calc(50% - 64px);
						width: 66px;
						height: 128px;
					}

					@include break(large) {
						width: 86px;
						height: 168px;
						top: calc(50% - 84px);
					}
				}
			}

			.hero-title {
				@include font-size(42);
				padding-right: 20%;
				display: block;
				margin-bottom: $gap / 3;
				color: $white;

				@include break(medium) {
					padding-right: 45%;
				}

				@include break(large) {
					padding-right: 55%;
				}
			}

			.hero-summary {
				width: 70%;
				margin-left: 0;
				display: inline-block;
				vertical-align: middle;

				@include break(medium) {
					width: 60%;
				}

				@include break(large) {
					width: 50%;
				}
			}

			a {
				display: table;
				vertical-align: middle;
				margin-left: 0;

				@include break(medium) {
					margin-top: 0;
					display: inline-block;
					margin-left: 30px;
				}

				&:not(.popup-video) {
					@extend .button.white;
				}

				&.popup-video {
					color: white;

					&:hover {
						span {
							box-shadow: inset 0px -5px 0px 0px $white;
						}
					}
				}
			}
		}
	}

	&-article-info {
		margin-top: $gap * 2;
		font-weight: $bold;
		padding-left: 80px;
		position: relative;

		&-author {
			color: $primary;
		}

		&-date {
			color: $grey40;
		}

		&:before {
			content: '';
			height: 60px;
			width: 60px;
			display: block;
			position: absolute;
			left: 0;
			top: calc(50% - 30px);
			background-color: $secondary;
			background-image: url(../images/logomark.svg);
			background-position: center;
			background-size: 25px 43px;
			background-repeat: no-repeat;
			border-radius: 100%;
		}
	}

	&-globe{

		.hero{
			padding-bottom:$gap * 5;

			&:after{
				content:'';
				height:$gap * 5;
				width:100%;
				left:0;
				bottom:0;
				background:white;
				z-index: 0;
				position:absolute;
			}
		}

		.container{
			z-index: 1;
		}

		&-container{
			position:absolute;
			right:0;
			top:0;
			width:60%;
			height:100%;
			background:red;
			z-index: 1;

			&:after{
				content:'';
				height:42px;
				width:80px;
				position:absolute;
				top:$gap * 2;
				right:$gap * 2;
				background-image:url('/assets/images/360.png');
				background-size:cover;
			}
		}

		&-back{
			position:relative;
			font-family:$font-header;
			text-transform:uppercase;
			color:white;
			@include font-size(16);
			background:none;
			box-shadow:none;

			&:after{
				content:'';
				height:70px;
				width:70px;
				background-image: url("data:image/svg+xml,%3Csvg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Crect x='10' y='10' width='40' height='40' fill='white'/%3E%3C/g%3E%3Cpath d='M37.0713 37.071L22.9292 22.9289' stroke='%23005FB6' stroke-width='4'/%3E%3Cpath d='M37.0713 22.9289L22.9292 37.071' stroke='%23005FB6' stroke-width='4'/%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0' width='70' height='70' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dx='5' dy='5'/%3E%3CfeGaussianBlur stdDeviation='7.5'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
				background-size:cover;
				display:inline-block;
				vertical-align:middle;
				margin-left:10px;
			}

			&:hover{
				background:none;
			}
		}

		&-explore{
			@include break(medium){
				display:none;
			}
		}

		& + .main-section{
			padding-top:0;
		}

		select{
			width:50%;
		}

		.select2-container{
			margin-top:$gap * 2;
			background:$primary;
			box-shadow:0px 0px 10px 5px rgba(black, 0.1);

			*{
				border-radius:0px !important;

				&:focus{
					border-radius:0px !important;
				}
			}			
		}

		.select2-container--default .select2-selection--single .select2-selection__arrow{
			height:40px;
			top:0;
			right:0;
			width:40px;

			b{
				border-color: #fff;
				border-style: solid;
				border-width: 0 0 4px 4px;
				height: 12px;
				left: calc(50% - 6px);
				margin-left: 0;
				margin-top: 0;
				position: absolute;
				top: calc(50% - 8px);
				width: 12px;
				transform: rotate(-45deg);
			}
		}

		.select2-selection__rendered{
			&:focus{
				border-radius:0px !important;
			}
		}

		.select2-container .select2-selection--single{
			height:40px;
		}

		.select2-container--default .select2-selection--single{
			border:none;
			background:$primary;
			color:white;
			border-radius:0px;
		}

		.select2-container--default .select2-selection--single .select2-selection__rendered{
			line-height:40px;
			font-family:$font-header;
			text-transform:uppercase;
			padding-left:12px;
			padding-right:40px;
		}

		.select2-container--default .select2-selection--single .select2-selection__rendered, .select2-container--default .select2-selection--single .select2-selection__placeholder{
			color:white;
		}

		.select2-container--default .select2-selection--single, .select2-container--default .select2-selection--multiple, .select2-container--default .select2-selection--multiple .select2-selection__choice, .select2-container--classic .select2-selection--single, .select2-container--classic .select2-selection--multiple .select2-selection__choice{
			border-radius:0px !important;
			border:none !important;
			outline:none !important;

			&:focus{
				border-radius:0px !important;
				outline:none !important;
			}

			&:focus-within{
				border-radius:0px !important;
				outline:none !important;
			}
		}
	}
}

// dropdowns stuff

.select2-results{
	.select2-results__option{
		font-family:$font-header;
		text-transform:uppercase;
		padding-left:12px;

		&:nth-of-type(odd){
			background:#f7f7f7;
		}
	}

	.select2-results__option--highlighted[data-selected]{
		background:$orange !important;
		color:white;
	}
}

.select2-dropdown{
	border-radius:0px;
	border:none;
	box-shadow:0px 0px 10px 5px rgba(black, 0.1);
}
/* +++++++++++++++++++++
+++ LAYOUT: METADATA +++
+++++++++++++++++++++ */

// Metadata on an article page
.metadata {
    @extend %font-meta;
    color: $secondary;

    &-link {
        @extend %text-hover;
        @extend %transition;
        font-weight: $bold;
    }

    time {
        font-weight: $bold;
    }
}

.metalabel {
    @extend %font-label;
    color: $primary;
    display: block;

    a {
        @extend %text-hover;
    }
}

// Tags

.tags {
	margin-top: $gap;
}
.metatag {
    float: left;
    margin: $gap / 4 $gap / 4 0 0;

    &-link {
        @extend %transition;
        background: $grey95;
        border-radius: $corner;
        color: $grey40;
        display: block;
        font: $bold 0.9em/1.5 $font-body;
        padding: $gap / 4 $gap / 2;

        &:hover {
            background: $secondary;
            color: $white;
        }
    }
}
/* +++++++++++++++++++++++++
+++ LAYOUT: FIXED HEADER +++
+++++++++++++++++++++++++ */

@if $fixedhead {
	.header {
		@include break(medium) {
		    left: 0;
		    position: fixed;
		    top: 0;
			transition: top 0.2s ease-in-out;
		}
		
	}
	.main {
	    @include break(medium) {
		    margin-top: $header-height;
	    }
	}
}
//++++++++++++++++++++++++
//+++ HELPERS: COOKIES +++
//++++++++++++++++++++++++

#onetrust-consent-sdk {
	#onetrust-policy-text {
		font-size: 1rem !important;
		margin-bottom: 0 !important;
		padding: 1rem 2rem !important;
		text-align: center !important;
		width: auto !important;
	}
	
	button {
		@extend %transition;
		border: 0 !important;
		border-radius: 0 !important;
		font-size: 0.8rem !important;
		letter-spacing: 0.75px !important;
		padding: 0.75rem 1.5rem !important;
		text-transform: uppercase !important;
		
		opacity: 1 !important;
		
		&:hover,
		&:focus,
		&:active {
			background-color: $primary-light !important;
			opacity: 1 !important;
		}
		
		&:focus {
			outline: 0 !important;
		}
	}
	
	button#onetrust-pc-btn-handler {
		// background-color: $orange !important;
		// color: $white !important;
		// 
		// &:hover,
		// &:focus,
		// &:active {
		// 	background-color: lighten($orange, 10%) !important;
		// }
	}
	
	.ot-close-icon {
		&:hover {
			background-color: transparent !important;
			opacity: 0.8 !important;
		}
	}
}
/* ++++++++++++++++++++
+++ LAYOUT: CONTENT +++
++++++++++++++++++++ */

// Content is the section of the page that contains body copy, typically from a WYSIWYG area. This gets all the post styling such as blockquotes and lists.

.content {
    @extend %font-content;
    
    * {
		margin-top: $gap;
		
        &:first-child {
	        margin-top: 0;
        }
    }

    // Lists
    ol, ul {
	    margin-top: $gap;
        padding-left: $gap * 1.2;
    }

    li + li {
        margin: $gap / 2 auto 0;
    }

    ol li {
        list-style: decimal;

        ol li { list-style-type: lower-roman; }
        ul li { list-style-type: circle; }
    }

    ul li {
        list-style: disc;

        ul li { list-style-type: circle; }
        ol li { list-style-type: decimal; }
    }

    form {
        ol, ul {
            padding: 0;
        }

        li {
            list-style: none !important;
        }
    }

    > p:not(.wp-caption-text) {
        font-size: 1em;

        &:empty {
            display: none;
        }
    }

    a {
        @extend %text-hover;
        text-decoration: underline;

        &:hover{
            color:$blue;
        }
    }

    // Media
    img {
        margin-top: $gap * 1.5;
        width:100%;
    }

    .fluid-width-video-wrapper {
        overflow: hidden;
    }

    a img {
        @extend %image-hover;
    }

    // Buttons
    .btn,
    .button {
        font-size: 1rem;
        margin-top: 1rem;
        
        + .btn, .button {
	        @include break(small) {
		        margin-left: 1rem;
	        }
        }
    }

    .btn a,
    .button a,
    a.btn,
    a.button {
        border: none;
        box-shadow: none;
        color: $white;
        text-decoration: none;
    }

    p.btn,
    p.button {
        padding:0;
        font-size:16px;
        box-shadow:none;

        a{
            padding: 5.33328px 16px;
            display:block;
        }
    }

    .btn a,
    .button a {
        @include font-size(16);
    }

    .pullquote{
        background:white;
        position:relative;
        margin:$gap * 1.5 0;
        text-align:left;
        padding:$gap * 1.5;
        border:solid 5px $grey;
        color:$primary;

        &-cite{
            font-weight:$bold;
            font-family:$font-header;
            color:$grey30;
            @include font-size(18);
            padding: 0;
            margin: $gap 0 0 0;
            background: transparent;
            display: block;
            width: 100%;

            a{
                text-decoration:none;
            }
        }

    }

    // Quotes
    blockquote {
        color:$primary;
        position: relative;
        position:relative;
			    
	    p {
            font-family:$font-body;
            display: block;
            font-family:$font-header;
            font-weight:$bold;
            @include font-size(28);
            line-height:1.3;
            padding: 0;
            position:relative;
            letter-spacing:-1.5px;


            @include break(tablet){
                @include font-size(35);
            }

        }
    }

    code {
        background: $grey95;
        border: 1px solid $grey80;
        font-family: $font-meta;
    }

    @include break(small) {
        > p:not(.wp-caption-text), li { font-size: 1.125em; }
        li li { font-size: 1em; }
    }

    @include break(medium) {
        .alignleft,
        .float-left,
        .float_left {
            float: left;
            margin: $gap * 1.5 $gap * 1.5 $gap * 1.5 0;
            max-width: 48%;
        }

        .alignright,
        .float-right,
        .float_right {
            float: right;
            margin: $gap * 1.5 0 $gap * 1.5 $gap * 1.5;
            max-width: 48%;
        }
    }

    .drop-cap{
        width: 86px;
        height: 86px;
        background: $secondary;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font-size(50);
        font-family: $font-header;
        color: white;
        font-weight: bold;
    }
}

@if $wordpress {
    .wp-caption {
        position: relative;
        width: auto !important;
        
	    &-text {
            position:absolute;
            bottom:0;
            right:0;
            width:100%;
            background:rgba(black, 0.8);
            color:white;
            padding:15px;
            @include font-size(13);

			@include break(small){
				width: 70%;
			}

			@include break(large){
				width: 40%;
			}

			&:before{
                content:'';
                height:15px;
                width:15px;
                background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 15V11' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 7H11.01' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                background-size: cover;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                margin-top:-2px;
            }

			a{
				text-decoration:underline;
			}
	    }
    }
}
@if $drupal {
    figure[role=group] {
        position: relative;
        width: auto !important;
        
	    figcaption {
	        @extend figcaption;
	    }
    }
}

pre {
	background-color: $white;
	border-radius: 3px;
	font-size: 85%;
	font-family: $font-meta;
	line-height: 1.45;
	margin: $gap * 3 0;
	overflow: auto;
	padding: $gap * 3;
}

p {
	pre {
		display: inline-block;
		padding: 0;
	}
}

.mailchimp-form{
    position:relative;

    &:after{
        content:'';
        height:50%;
        width:100%;
        position:absolute;
        background:$primary;
        bottom:0;
        left:0;
        z-index: 0;
    }

    // .section-header{
    //     text-align:left;
    // }

    // .section-header-title{
    //     color:white;
    // }

    // .section-header-summary{
    //     margin-left:0;
    // }

    form{
        background:white;
        box-shadow:$shadow;
        position:relative;
        z-index: 1;
        padding:30px;
        margin-top:$gap * 2;
        border-top:solid 10px $secondary;

        p{
            margin-top:$gap;
        }

        [type="submit"]{
            
            margin-top:$gap * 2;
        }

        input[type="radio"]{
            @extend %checkbox;
        }

        label{
            font-weight:$bold;
        }

        .form-type-radio{
            display:inline-block;
            vertical-align:middle;
            
            & + .form-type-radio{
                margin-left:10px;
            }
        }
    }

}


%checkbox{
    position: absolute; 
    opacity: 0; 
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    
    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        

        display: inline-block;
        padding-left: 40px;
        width: 100%;

        a{
            display: inline;
            vertical-align: baseline;
            text-decoration:underline;
        }
    }
    
    & + label:before {
        content: '';
        margin-right: 10px;
        display: block;
        position:absolute;
        top: calc(50% - 15px);
        left:0;
        width: 30px;
        height: 30px;
        margin-bottom:3px;
        border-radius:100%;
        border:solid 1px $secondary;
        border-radius:100%;
    }

    & + label:after {
        content:'';
        position:absolute;
        border-radius:100%;
        width: 22px;
        height: 22px;
        top: calc(50% - 11px);
        left: 4px;
    }
    
    &:hover + label:after {
        background:rgba(black,0.1);
    }

    &:hover:checked + label:after {
        background:transparent;
    }
  
    &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }
    
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }
    
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    &:checked + label:before{
        box-shadow:inset 0px 0px 0px 3px white;
        background:$primary;
    }
    
    &:checked + label:after {
        content: '';
        position: absolute;
        width: 6px;
        height: 12px;
        top: calc(50% - 8px);
        left: 12px;
        transform: rotate(45deg);
        border-right: solid 2px white;
        border-bottom: solid 2px white;
        border-radius:0;
    }
}
//++++++++++++++++++++++++
//+++ HELPERS: BUTTONS +++
//++++++++++++++++++++++++

// Button mixin
%button {
    @extend %transition;
    @extend %font-body;
    font-weight: $bold;
    padding: $gap / 3 $gap;
    text-align: center;
    box-shadow:$shadow;
    display: inline-block;
    width: auto;

}

.btn,
.button {
    @extend %button;
    background: $secondary;
    color: $white;
    @include font-size(20);
    text-transform:uppercase;
    font-family:$font-header;

    &:hover {
        background:$primary; 

        .primary &{
            background:$white;
            color:$primary;
        }
    }

    .secondary &{
        background:$white;
        color:$primary;

        &:hover {
            background:$primary;
            color:white; 
        }
    }

    &:active {
        background: $secondary-light;
    }

    &.white{
        background:White;
        color:$primary;

        &:hover {
            background: $secondary;
            color:white;
        }
    
        &:active {
            background: $secondary-dark;
            color:white;
        }
    }

    &.green{
        background:$secondary;
        color:white;

        &:hover {
            background: $primary;
            color:white;
        }
    
        &:active {
            background: $primary-dark;
            color:white;
        }
    }

    &.red{
        background:$red;
        color:white;
        padding: $gap / 2 $gap;

        &:hover {
            background: $primary;
            color:white;
        }
    
        &:active {
            background: $primary-dark;
            color:white;
        }
    }

    &.orange{
        background: $orange;
        color: $white;

        &:hover {
            background: $primary;
        }
    }
}

.btn-secondary,
.button-secondary {
    @extend %button;
    box-shadow: inset 0 0 0 $line rgba($black, 0.2);

    &:hover {
        box-shadow: inset 0 0 0 $line currentColor;
    }

    &:active {
        background: rgba($black, 0.1);
    }
}


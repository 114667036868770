//++++++++++++++++++++++
//+++ HELPERS: FORMS +++
//++++++++++++++++++++++

%form-input {
    @extend %transition;
    color: $black;
    border: $line solid $grey80;
    font: $normal 1em/1.5 $font-body;
    height: 50px;
    padding: 0 $gap;
    width: 100%;

    @include placeholder(){
        color:black;
        opacity:1;
    }

    &:disabled {
        background: $grey90;
    }
}

form {
    font: 1rem $font-body;

    p { font: 1em $font-body; }
}

input,
textarea,
select {
    @extend %form-input;
}

[type="text"],
[type="number"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="time"],
[type="search"],
[type="tel"],
[type="url"],
[type="email"],
[list],
textarea,
select {
    background: $white;
    outline: none;

    &:focus {
        border-color: $primary;
    }	
}

textarea {
    resize: vertical;
    padding: $gap / 2 $gap;
    height: auto;
    min-height: $gap * 8;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    @extend .button;
    -webkit-appearance: none;
    border: none;
    cursor: pointer;
    color:white;
}

[type="color"],
[type="file"] {
    border: none;
    line-height: 1;
    padding: 0;
}

[type="file"] {
    height: auto;
}

select {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background-image: url('../images/select-arrow.svg');
	background-position: calc(100% - 20px) center;
	background-repeat: no-repeat;
	background-size: 25px;
	border: solid 1px #A1AEB7;
	
	@include break(small) {
		background-image: url('../images/select-arrow.svg');
		background-position: calc(100% - 20px) center;
		background-repeat: no-repeat;
	}
	&::-ms-expand {
	    display: none;
	}
}

label {
    font: $normal 1em/1.5 $font-body;
    display: block;
    margin-top:$gap;
}

legend {
    border-bottom: $line solid $grey90;
    padding-bottom: $gap / 2;
    width: 100%;
}

[type="checkbox"],
[type="radio"] {
    float: left;
    height: $gap * 1.5;
    margin-right: $gap / 2;
    width: auto;

    & + label {
        display: inline;
        font-style: normal;
    }
}

@if $wordpress {
    .gform_body {
		.ginput_container {
			* {
				margin-top: 0;
			}
			.gfield_checkbox {
				input[type=checkbox] {
					margin-top: 0 !important;
				}
			}
		}
        select + label,
        input[type="text"] + label {
            color: $grey50;
            font-style: italic;
            margin: 0 auto $gap;
        }

        label + * {
            margin-top: $gap / 4;
        }

        li.gfield + li.gfield {
            margin-top: $gap * 2;
        }

        .gfield_required {
            color: $red;
        }
    }
}

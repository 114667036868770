/* +++++++++++++++++
+++ LAYOUT: FEED +++
+++++++++++++++++ */

// Feed is the basic listing of content such as news posts, more scannable and content heavy than cards

.feat-items {
	.item {
		background-color: $white;
		border-radius: $corner;
		padding-bottom: 0;
		
		&-image {
			border-radius: $corner $corner 0 0;
		}
		&-info {
			padding: $gap * 1.5;
		}
		
		+ .item {
			border-top: 0;
			margin-top: $gap * 2;
			padding-top: 0;
		}
	}
	
	@include break(medium) {
		.item {
			&-image {
				border-radius: 0 $corner $corner 0;
			}
		}	
	}
}

.item {
	@extend %flex;
	flex-direction: row-reverse;
    padding: $gap * 2 $gap * 3 $gap * 3 $gap * 3;
    box-shadow:$shadow;
    border-top:solid 10px $secondary;
    transition: ease 0.3s;

    @include break(medium){
        max-width:80%;
    }

    &-image {
	    @include flexbox(100%);
        @extend %image-hover;
        border-radius: $corner;
        
        img {
			@extend %object-fit;
        }
    }
    
    &-info {
		@include flexbox(100%);
	    margin: 0;
    }

    &-link {
        @extend %text-hover;
    }

    &-header {
        margin-top: $gap / 2;

        &-title {
            color:$primary;

            a {
                &:hover, .hovered &{
                    text-decoration:underline; 
                }
            }
        }

        &-date, &-author {
            display: inline-block;
            margin: $gap / 2 0;
        }
        &-author {
	        &:before {
		        content: '|';
		        display: inline-block;
		        margin: 0 $gap / 2;
	        }
        }
    }
    
    &-distance,
    &-summary,
    &-link {
        margin-left: 0;
        max-width: $half-column;
        margin-top:$gap;        
    }
    
    &-distance {
        font-weight: $bold;
    }

    &-summary {
        .postcode-listing & {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
            -webkit-box-orient: vertical;
        }
    }
    
    &-link { 
        .button-link {
            font-family: $font-header;
            color: $secondary;
            text-transform: uppercase;
            @include font-size(18);
            transition: ease 0.3s;
            box-shadow: inset 0px -3px 0px 0px transparent;
    
            &:hover {
                box-shadow: inset 0px -3px 0px 0px $secondary;
    
                .listing & {
                    box-shadow: none;
                }
            }
        }
    }

    & + & {
        margin-top: $gap * 2;
        padding-top: $gap * 2;
    }

    &.hovered{
        box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.4);
    }
}

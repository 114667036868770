/* +++++++++++++++++++
+++ LAYOUT: FOOTER +++
+++++++++++++++++++ */

// Layout of the site footer

.footer {
	padding-bottom: 0;
	padding-top: $gap * 2;
	position: relative;
	padding-bottom: 500px;

	@include break(medium) {
		padding-bottom: 1000px;
		padding-top: $gap * 4;
	}

	&.section.dark {
		background: transparent;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		height: calc(100% - 500px);
		width: 100%;
		background: linear-gradient(180deg, #022D81 0%, #1676CC 100%);

		@include break(medium) {
			height: calc(100% - 1000px);
		}
	}

	.container {
		position: relative;
		margin-bottom: -50px;
		z-index: 1;
	}

	&-sock {
		background: white;
		padding: $gap * 1.5 0;
		box-shadow: inset 0px 5px 7px -4px rgba(black, 0.2);

		@include break(tablet) {
			padding: $gap * 2.5 0;
		}

		.container {
			@extend %flex;
			justify-content: space-between;
			align-items: center;

			ul {
				margin-right: 0;
				width: 100%;

				@include break(medium) {
					flex: 1;
					width: auto;
					padding-left: 50px;
				}

				li {
					display: inline-block;
					vertical-align: middle;
					width: 100%;
					text-align: center;

					@include break(small) {
						width: 33.33%;
					}

					a {
						font-family: $font-header;
						text-transform: uppercase;
						color: $primary;
						@include font-size(30);

						@include break(medium) {
							@include font-size(38);
						}

						&:hover {
							box-shadow: inset 0px -6px 0px 0px $orange;
						}
					}
				}
			}
		}

		.news-single & {
			z-index: 1003;
			position: relative;
		}
	}

	&-logo {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 20px;
		width: 130px;
		height: 78px;

		@include break(medium) {
			margin-left: 0;
			margin-bottom: 0px;
			width: 170px;
			height: 100px;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

	&-nav {
		&.social {
			text-align: left;

			@include break(medium) {
				text-align: center;
			}

			li {
				display: inline-block;
				vertical-align: middle;
				height: 50px;
				width: 50px;
				background: white;
				margin: 0 15px 0 0;
				transition: ease 0.3s;

				@include break(medium) {
					margin: 0 15px;
					height: 60px;
					width: 60px;
				}

				a {
					display: block;
					height: 100%;
					position: relative;
					transition: ease 0.3s;

					svg {
						width: 35px;
						height: 35px;
						@include centerer(true, true);
					}
				}

				&:hover {
					background: $orange;

					a {
						color: white;
					}
				}
			}
		}
	}

	&-menu {
		@extend %flex;
		padding: $gap * 2 0 $gap * 2 0;

		@include break(medium) {
			padding: $gap * 4 0 $gap * 4 0;
		}

		ul {
			@include flexbox(100%);
			text-align: left;

			@include break(tablet) {
				@include flexbox(33.3333%);
			}

			@include break(medium) {
				text-align: center;
			}

			li {
				margin-top: 5px;

				@include break(medium) {
					margin-top: 15px;
				}
			}

			a {
				font-family: $font-body;
				color: white;
				@include font-size(16);
				font-weight: $normal;
				text-transform: none;
				display: inline-block;
				line-height: 1;
				margin: 0;

				@include break(medium) {
					@include font-size(20);
					line-height: 0.9;
				}

				&.external {
					padding-right: 22px;
					position: relative;

					&:after {
						content: '';
						height: 12px;
						width: 12px;
						background: url('../images/external-link.svg');
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						position: absolute;
						right: 0;
						top: calc(50% - 4px);
					}
				}

				&:hover {
					box-shadow: inset 0px -3px 0px 0px #EE7203;
				}
			}

		}
	}

	&-signup {
		padding: $gap * 2 0;
		border-width: 1px 0 1px 0;
		border-style: solid;
		border-color: White;

		@include break(medium) {
			text-align: center;
			padding: $gap * 6 $gap * 4;
		}

		&-title {
			@extend %h1;
			text-transform: uppercase;
			line-height: 0.8;
			@include font-size(50);
			color: white;

			@include break(small) {
				@include font-size(80);
			}

			@include break(medium) {
				@include font-size(100);
			}
		}

		&-copy {
			color: black;
			padding: $gap * 1.5 0;
			max-width: 70%;
			margin-left: 0;
			@include font-size(18);

			@include break(medium) {
				margin: auto;
				@include font-size(24);
			}
		}

	}

	&-bottom {
		@extend %flex;

		&-credits {
			margin-left: 0;
			color: black;
			width: 100%;

			@include break(medium) {
				width: auto;
			}

			p:last-of-type {
				margin-top: $gap * 1;
			}

			a {
				text-decoration: underline;
			}
		}

		&-worldwide {
			padding: $gap * 1.5 $gap $gap * 1.5 0;
			color: black;


			a {
				font-weight: $bold;
				padding-bottom: 5px;

				&:hover {
					box-shadow: inset 0px -3px 0px 0px $orange;
				}
			}
		}

		>a {
			margin: 20px 0 0 0;

			@include break(medium) {
				margin: 0 0 0 auto;
			}

			img {
				width: 211px;
				height: 65px;
			}
		}
	}

	&-tools {
		width: 100%;
		text-align: left;
		padding: $gap * 1.5 0;
		border-bottom: solid 1px white;
		border-top: solid 1px white;
		margin-bottom: $gap * 2;

		@include break(medium) {
			text-align: center;
		}

		li {
			display: inline-block;
			vertical-align: middle;
			width: 49%;

			@include break(medium) {
				margin: 0 10px;
				width: auto;
			}

			@include break(large) {
				margin: 0 20px;
				padding: 0 20px;
			}

			a {
				font-family: $font-body;
				color: black;
				text-transform: none;
				@include font-size(14);
				font-weight: $normal;
				padding: 5px 0;
				margin: 0;

				@include break(medium) {
					margin: 0 10px;
					@include font-size(16);
				}

				display:inline-block;

				&:hover {
					box-shadow: inset 0px -3px 0px 0px $orange;
				}
			}
		}
	}

	&-image {
		position: absolute;
		width: 100%;
		height: 500px;
		z-index: 0;
		bottom: 0;

		@include break(medium) {
			height: 1000px;
		}

		&:before {
			content: '';
			height: 300px;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: linear-gradient(180deg, #1676CC 0%, transparent 100%);
		}

		img {
			@extend %object-fit;
		}

		&-credit {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			padding: 30px;
			background: rgba(black, 0.8);

			@include break(small) {
				width: 60%;
			}

			@include break(large) {
				width: 30%;
			}

			p {
				&:before {
					content: '';
					height: 15px;
					width: 15px;
					background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 15V11' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11 7H11.01' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					background-size: cover;
					display: inline-block;
					vertical-align: middle;
					margin-right: 8px;
					margin-top: -2px;
				}
			}

			a {
				text-decoration: underline;
			}
		}
	}

	.news-single & {
		z-index: 1003;
		position: relative;
	}
}
.tooltips{
    position:absolute;
    top:0;
    right:0;
    width:50%;
    height:100%;
    z-index: 3;

    .country{
        position:absolute;
        top:5%;
        left:30%;
    }

    .office{
        position:absolute;
        bottom:5%;
        left:30%;
    }
}


.tooltip{
   background:white;
   filter:drop-shadow(2px 4px 6px rgba(black, 0.2));
   max-width:400px;
   position:relative;
   display:none;

   @include break(medium){
       display:block;
   }

   &:after{
       content:'';
       height:30px;
       width:30px;
       position:absolute;
       bottom:-15px;
       left:calc(50% - 20px);
       transform:rotate(45deg);
       background:white;
       z-index: -1;
   }

   &-title{
       padding:$gap $gap * 1.5;

       p{
           font-family: $font-header;
           @include font-size(22);
       }

       .country &{
           background:$green;
           color:white;
       }

       .office &{
            background:$grey90;
            color:$primary;
        }
   }

   &-summary{
       padding: $gap $gap * 1.5;
   }

   &-cta{
       padding:0 $gap * 1.5 $gap $gap * 1.5;

       a{
           color:$green;
           font-family: $font-header;
           text-transform:uppercase;
           transition: ease 0.3s;

           &:hover{
               box-shadow:inset 0px -3px 0px 0px #52ae32;
           }
       }
   }
}